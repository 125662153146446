import React from 'react'
import HubspotFrom from '../HubspotForm/Hubspot'
import cImg from '../../images/contact/contact.jpg'


const ConsultingArea = (props) => {
    return (
        <section id="contact" className="relative bg-center bg-cover bg-no-repeat bg-fixed py-[100px] z-10
   before:content-[''] before:bg-[rgba(21,26,48,0.9)] before:w-full before:h-full before:absolute before:left-0 before:-z-10 before:top-0 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]" style={{ backgroundImage: `url(${cImg})` }}>
            <div className="wraper">
                <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-5 md:col-span-12 ">
                        <div className="contact-text md:mb-[30px]">
                            <div className="title">
                                <h2 className="text-[36px] md:text-[26px] font-medium text-white pb-[20px] md:pb-[10px] relative
                        before:absolute before:content-[''] before:left-[-68px]
                        before:top-[30px] before:transform before:-translate-y-1/2
                        before:w-[60px] before:h-[1px] before:bg-[#867662] md:before:hidden">Contact Me</h2>
                            </div>
                            <div className="mt-[30px] pr-[150px] leading-[30px] text-[16px]">
                                <p className="text-[#ddd]">To learn more about my services, experience, and all things metal roofing! I look forward to connecting with you!</p>
                                <p className="text-[#ddd] mt-[20px]"><i class="ti-mobile mr-[8px]"></i> 719-495-8407</p>
                                <p className="text-[#ddd] mt-[20px]"><i class="ti-email mr-[8px]"></i> <a href="mailto:rob@rmhaddock.com">rob@rmhaddock.com</a></p>
                                <p className="text-[#ddd] mt-[20px] addressContact"><i class="ti-location-pin mr-[8px]"></i><span>12730 Black Forest Road<br />Colorado Springs, CO<br />80908</span></p>
                                <p className="text-[#ddd] mt-[20px]"><i class="ti-linkedin mr-[8px]"></i> <a href="https://www.linkedin.com/in/robhaddock/">LinkedIn</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-7 md:col-span-12">
                        <HubspotFrom />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ConsultingArea;