import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Standing seam metal roofing provides the perfect platform for mounting rooftop accessories. The uniqueness of metal roofing is that the structural seams can be used as inherent (and cost-free) mounting points via reliable mechanical attachments for a myriad of rooftop services and accessories.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Accessories can include snow retention, fall protection and wind performance systems, satellite dishes, solar arrays, signs/banners, light fixtures, stack/flue bracing, service walkways, HVAC, lightning protection, equipment screens, conduit, condensate lines and more.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Determining the appropriate clamps required for your project is math and science-based — not guesswork. It is contingent on the specific application of the product on a given project. The load (or force) that any connection experiences in service must be matched to the allowable strength of the clamp for a metal roof’s specific gauge, profile and manufacturer.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Bottom line — utilize clamps specifically designed and engineered for the loads they will see in service and evidenced by certified test results to prove it. Check for metallurgical compatibility and clamp durability that last the life of the roof, etc.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The Metal Construction Association technical bulletin on Fastener Compatibility with Profiled Metal Roof and Wall Panels is a great resource for additional information on compatibility and durability.
            </p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               What’s the Difference Between One Clamp vs. Another?
            </h3>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Let’s look at the design difference between a clamp used for snow retention vs. a clamp used for solar mounting. The primary load directions are different: snow is a vector force parallel to the slope of the roof; wind acting on a solar module is a “negative normal” load (perpendicular to the slope and pulling up). This is why holding strength must be tested in both load directions and compared with actual job-specific design loads for either — or both. Seismic is a third-acting in lateral direction. Test reports should identify the same roof material, profile and manufacturer as used on the project.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Testing Clamp Capacity
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Clamps transfer loads (forces) into the roof panels and from there into the structure. This is why seam and roof panel profile-specific testing is vital. The holding strength on one seam profile, material and gauge thickness is not the same as the next. And, the holding strength is not the same in all load directions.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Each clamp should be tested for load capacity in different load directions on the same seam profile from the same roof manufacturer. Still, in different materials and gauge thicknesses, those values vary widely and will produce different results. So, it is a matrix of many possible combinations. One size may fit all, but doesn’t render the same holding strength. That’s why S-5! has conducted more than 3,000 individual load tests on every conceivable load direction, material type, gauge thickness and brand of manufacture.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Our test results range from 600# ultimate to 4,000# ultimate, depending on all the aforementioned variables. Ultimate loads must be reduced by a factor of safety when engineering the application to ensure the connection doesn’t fail in service.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Using the Wrong Clamp
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               A lot of folks (including vendors) focus on “fit”: Does the clamp ‘fit’ the seam?” Fit is only a first-step criterion, and there are many others of greater significance. Metallurgical compatibility is one issue. Adequate, appropriate and certified testing is another. Measuring these other factors enables competent engineering of the attachment to do what it is supposed to. Certified manufacturing ensures that the product tested is the product actually shipped. Uncertified manufacturing doesn’t.
            </p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Do’s & Don’ts
            </h3>
            <ul className="list-disc list-inside pl-[20px]">
               <li className="mb-[10px]">
                  Installed in strict accordance with manufacturer’s instructions, then the monkey is on his back (not yours) if the attachment fails.
               </li>
               <li className="mb-[10px]">
                  Proven through certified testing to withstand intended design loads.
               </li>
               <li className="mb-[10px]">
                  Engineered and designed in accordance with project specifics.
               </li>
               <li className="mb-[10px]">
                  Produced in an audited facility with quality standards to assure product consistency.
               </li>
               <li className="mb-[10px]">
                  Proven to perform for the entire anticipated roof service life.
               </li>
               <li className="mb-[10px]">
                  Limiting your liability with proof of all the above from the vendor.
               </li>
            </ul>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Don’t believe sales claims without requesting the proof of claim: a copy of the warranty, a copy of the test results, a copy of the ISO-9001-15 certificate, etc.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               If the installer is involved with product selection, the forgoing tips are also vital because you are now liable not only for correct installation but for correct product selection as well.
            </p>
         </div>
      </div>

   )
}

export default blogPage;