import React from "react";
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import { Link } from 'react-router-dom'
import { BrowserRouter as Router, Route, Link as RouterLink } from 'react-router-dom';
import hero from '../../images/slider/hero.png'
import { Link, animateScroll as scroll } from 'react-scroll';


const Hero = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="hero relative h-[662px] lg:h-[500px] col:h-[450px] overflow-hidden z-20 bg-[#1D2E52]">
            <div className="hero-slider">
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, A11y]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    speed={1800}
                    parallax={true}
                    navigation
                    pagination={{ clickable: true }}
                >
                    <SwiperSlide>
                        <div className="slide-inner slide-bg-image h-[662px] lg:h-[500px] col:h-[450px]">
                            <div className="slide  relative h-full bg-cover bg-no-repeat z-10">
                                <div className="wraper grid grid-cols-2 md:grid-cols-1 gap-20">
                                    <div className="slide-caption mt-[240px] md:mt-[140px] text-center">
                                        <h2 className="text-white font-bold">
                                            <span className="text-[#867662] text-[100px] md:text-[75px] leading-[85px]">MRAG</span><br />
                                            <span className="text-[35px] md:text-[25px] mb-[60px]">Metal Roof Advisory Group</span><br />
                                            <span className="text-[20px] leading-[30px]">Ltd.</span></h2>
                                        <div className="btns">
                                            <div className="btn-style">
                                                <Link onClick={ClickHandler} activeClass="active"
                                                    to="contact"
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-0}
                                                    duration={300} className="bg-[#867662] relative cursor-pointer text-[22px]
                             font-bold text-white px-[8px] py-[2px] mt-[40px]  capitalize inline-block transition ease-in-out duration-300 hover:bg-[#d4c291] border border-[#BEB499] border-[2px]">Contact <i className='ti-angle-right'></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide-img mt-[91px]">
                                        <img className="w-full" src={hero} alt="Metal Roof Graphic" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slide-inner slide-bg-image h-[662px] lg:h-[500px] col:h-[450px]">
                            <div className="slide  relative h-full bg-cover bg-no-repeat z-10">
                                <div className="wraper grid grid-cols-2 md:grid-cols-1 gap-20">
                                    <div className="mt-[91px] mx-[30px]">
                                        <iframe width="800" height="450" src="https://www.youtube.com/embed/mftvQIDXzXw?si=qNaWQQI0pM_7Nj7R" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>
                                    <div className="slide-caption mt-[140px] md:mt-[140px] text-center">
                                        <p className="text-white font-bold px-[25px]">
                                            Rob regularly presents at domestic and international events worldwide. He is best known for his Metal Roofing Systems course, otherwise known as Metal Roofing A to Z. He presents this course annually at tradeshows like METALCON and the International Roofing Expo and via the Roofing Contractors Association of British Columbia (RCABC). He offers an extended version at the University of Wisconsin–Madison in the College of Engineering.
                                        </p>
                                        <p className="text-white font-bold mt-[15px] px-[25px]">
                                            He is a down-to-earth speaker who melds technical knowledge with years of hands-on experience and a twist of country wit for an informative, entertaining presentation.
                                        </p>
                                        <div className="btns">
                                            <div className="btn-style">
                                                <RouterLink onClick={ClickHandler} to="/Services" className="bg-[#867662] relative cursor-pointer text-[22px]
                             font-bold text-white px-[8px] py-[2px] mt-[40px]  capitalize inline-block transition ease-in-out duration-300 hover:bg-[#d4c291] border border-[#BEB499] border-[2px]">Event Information <i className='ti-angle-right'></i></RouterLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    )
}

export default Hero;