import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Most accomplished solar contractors are knowledgeable and experienced in solar photovoltaic (PV) design and installation from an electrical perspective, but few have in-depth knowledge of the various roofing systems out there, their benefits and drawbacks. Let’s talk about metal roofs today.
            </p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               The Benefits of Metal Roofs for Solar PV Mounting
            </h3>
            <ul className="list-disc list-inside pl-[20px] mb-[20px]">
               <li className="mb-[10px]">
                  <span className="font-bold">Extended Service Life:</span> Metal roofs have a service life of 50-70 years, exceeding the average lifespan of a solar PV system (32.5 years). This longevity minimizes the need for costly disassembly and re-roofing.
               </li>
               <li className="mb-[10px]">
                  <span className="font-bold">Sustainability:</span> With a 73% recycle rate, steel is the most recycled construction material, making metal roofs environmentally friendly.
               </li>
               <li className="mb-[10px]">
                  <span className="font-bold">Lower Installation Costs:</span> Metal roofs, especially standing seam, offer lower installation costs compared to alternative roofing materials, resulting in significant cost savings when combined with solar installation.
               </li>
            </ul>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               General Requirements of Mounting PV Arrays on Metal Rooftops
            </h3>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               When mounting PV on metal roofs, it is important to ensure continued roof durability and warranties are not jeopardized. Mounting rooftop PV should always be consistent with the design principles of the host roof, which vary according to the specific metal roof type.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               PV arrays on rooftops are exposed to environmental forces like wind, snow, rain, hail, and earthquakes. Secure attachments of PV arrays are crucial to prevent damage or injury. Consulting with roofing contractors who installed the host roof is advisable, as they have extensive knowledge of local weather conditions.
            </p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Types of Solar Mounting Systems on Metal Roofs
            </h3>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Solar modules are secured to metal roofs by flush-mounted or tilted methods, each with its own energy output and cost implications. Flush-mounted systems are now favored for their higher power density and lower installation costs.
            </p>
            <h4 className="text-[#000] text-[18px] leading-[28px] mb-[10px] font-bold">
               Rail Mounted on Rooftops
            </h4>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               A typical rail-mounted system utilizes aluminum or light-gauge coated steel rails mounted above the seams or ribs of a metal roof. Rails traverse the seams or ribs, allowing for maximum module density.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               In climates prone to snow accumulation, the forces acting on the surface of the module create an eccentric loading at the rails’ attachment points, which must be considered in system design.
            </p>
            <h4 className="text-[#000] text-[18px] leading-[28px] mb-[10px] font-bold">
               Rail-less Mounted (Direct-attached)
            </h4>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Solar modules may mount directly to the seams of a standing seam metal roof or to the ribs of a face-fastened metal roof, eliminating the need for rails. This method provides a more uniform load distribution and significant cost savings.
            </p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Why Use Rail-Less Direct Attach Solar Mounting Solutions?
            </h3>
            <ul className="list-disc list-inside pl-[20px] mb-[20px]">
               <li className="mb-[10px]">
                  <span className="font-bold">Reduced Material Cost</span>
               </li>
               <li className="mb-[10px]">
                  <span className="font-bold">Reduced Freight Cost</span>
               </li>
               <li className="mb-[10px]">
                  <span className="font-bold">Reduced Labor Cost</span>
               </li>
               <li className="mb-[10px]">
                  <span className="font-bold">Reduced Handling Logistics</span>
               </li>
               <li className="mb-[10px]">
                  <span className="font-bold">Reduced Dead Load to Roof</span>
               </li>
               <li className="mb-[10px]">
                  <span className="font-bold">Better Distribution of Load</span>
               </li>
               <li className="mb-[10px]">
                  <span className="font-bold">Faster on-and-off the Job</span>
               </li>
               <li className="mb-[10px]">
                  <span className="font-bold">Tested, Engineered Strength to Resist Any Wind Speed</span>
               </li>
            </ul>
         </div>
      </div>

   )
}

export default blogPage;