import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage/HomePage'
import AboutPage from '../AboutPage/AboutPage';
import BlogPage from '../BlogPage/BlogPage'
import BlogDetails from '../BlogDetails/BlogDetails'
import PracticePage from '../PracticePage/PracticePage';
import ContactPage from '../ContactPage/ContactPage';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="services" element={<PracticePage />} />
          <Route path='article/:slug' element={<BlogDetails />} />
          <Route path='articles' element={<BlogPage />} />
          <Route path='contact' element={<ContactPage />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
