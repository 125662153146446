import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Standing seam metal roofing offers a unique platform for mounting a myriad of rooftop services and accessories, such as snow retention, service walkways and solar panels as the seams of the roof act as inherent, structural (and cost-free) attachment rails for mounting accessories via non-penetrative mechanical methods.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Determining the appropriate clamps required for each project is math and science-based—not guesswork. It is contingent on the specific application for any given project. The load (or force) that any connection experiences in service must be matched to the allowable strength of the clamp for the metal roof’s specific gauge, profile and manufacturer.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Bottom line―utilize clamps specifically designed and engineered for the service loads they will experience and proven by certified test results. Check clamp design for metallurgical compatibility, and material durability that lasts the life of the roof.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The Metal Construction Association’s technical bulletin on Fastener Compatibility with Profiled Metal Roof and Wall Panels is a great resource for the latter information.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               What’s the Difference Between One Clamp versus Another?
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Let’s look at the design difference between a clamp used for snow retention versus a clamp used for a solar mounting. The primary load directions are different: snow presents a vector force parallel to the slope of roof; wind on solar modules is a “negative normal” load (perpendicular to the slope and pulling up). Therefore, holding strength must be tested in both load directions and compared with actual job-specific design loads for either―or both. Seismic is a third―acting in lateral direction.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Testing Clamp Capacity
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Clamps transfer load (force) into the roof panels and from there, into the structure. This is why seam and roof panel profile-specific testing is vital. The holding strength on one seam profile, material, gauge thickness is not the same as the next. And the holding strength is not the same in all load directions.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Each clamp should be tested for load capacity in different load directions on the same seam profile from the same roof manufacturer but in different materials and gauge thicknesses. Those values vary widely and will produce vastly different results. So, it is a matrix of many possible combinations. One size may fit all, but it doesn’t render the same holding strength.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               That’s why S-5! has conducted 3,000-plus individual load tests on every conceivable load direction, material type, gauge thickness and brand of manufacture—with test results ranging from 600# ultimate to 4,000# ultimate, depending on the aforementioned variables. Failure (ultimate) loads must be reduced by a factor of safety when engineering the application to ensure the connection doesn’t fail in service.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               A Frequent Misstep
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               A lot of folks (including vendors) focus on “fit”―”Does the clamp ‘fit’ the seam?” This is only a first-step criterion, and there are many others of greater significance. Metallurgical compatibility is one issue. Appropriate certified testing is another. This enables competent engineering of the attachment to do what it is supposed to. Certified material and manufacturing ensure that the product tested is the product actually shipped.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Use products that are:
            </p>

            <ul class="list-disc list-inside pl-[20px]">
               <li class="mb-[10px]">Recommended by the roof manufacturer</li>
               <li class="mb-[10px]">Proven through certified testing to withstand intended design loads</li>
               <li class="mb-[10px]">Engineered and designed in accordance with project specifics</li>
               <li class="mb-[10px]">Produced in an audited facility with quality standards to assure product consistency</li>
               <li class="mb-[10px]">Proven by track record to perform for the entire anticipated roof service life</li>
               <li class="mb-[10px]">Limiting your liability with proof of all the above from the vendor</li>
               <li class="mb-[10px]">Offered with a meaningful warranty (always read the fine print)</li>
            </ul>
         </div>
      </div>

   )
}

export default blogPage;