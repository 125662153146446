import React from 'react'
import about from '../../images/about/story.jpg'


const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="pt-[120px] pb-[100px] border border-[#F2EFE8] border-t-[33px]">
            <div className="wraper">
                <div className="grid grid-cols-12 items-center gap-3">
                    <div className="col-span-6 md:col-span-12">
                        <div className="relative mb-[20px]">
                            <div className=" relative max-w-[520px] before:absolute before:bg-[#BEB499] before:w-[107.38%] before:h-[110.5%] before:content-[''] before:-z-10 before:left-[-13%] before:top-[-3.5%] before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
                                <img className="w-full" src={about} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 md:col-span-12">
                        <div className="mb-[20px]">
                            <h2 className="text-[#202020] text-[24px] font-bold relative pb-[8px] before:absolute before:content-[''] before:bottom-0 before:transform before:-translate-x-[10px] before:w-[230px] before:h-[3px] before:bg-[#999999] before:rounded-full before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">The Story of S-5!</h2>

                            <div className="pl-[10px] mt-[23px]">
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Founded by a veteran metal roof expert, S-5! has been the leading authority on metal roof attachment solutions since 1992. S-5!’s lifetime, zero-penetration clamps and brackets attach ancillary items to standing seam and exposed-fastened metal roofs respectively, while maintaining roof integrity and warranties.</p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal"> S-5! solutions are engineered for a variety of roof-mounted applications: snow retention, fall protection and wind performance systems, satellite dishes, solar arrays, signs/banners, light fixtures, stack/flue bracing, walkways, HVAC, lightning protection, equipment screens, conduit, condensate lines and more.</p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal"> Made in the U.S.A., S-5!’s solutions have revolutionized the metal roofing industry and are now installed on more than 2.5 million metal roofs worldwide, including 8 gigawatts of solar PV, providing strength and longevity never before seen.
                                    For more information, visit www.S-5.com.</p>
                                <div className="mb-[50px] col:mb-[20px]">
                                    <a onClick={ClickHandler} target="_blank" href="https://www.s-5.com/" className="bg-[#867662] relative cursor-pointer text-[22px]
                                font-semibold text-white px-[8px] py-[8px] mt-[40px]  capitalize inline-block transition ease-in-out duration-300 hover:bg-[#d4c291] border border-[#BEB499] border-[2px]" rel="noreferrer">Visit S-5! <i className='ti-angle-right'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;