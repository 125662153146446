import React from 'react'
import about from '../../images/about/rob2.jpg'


const About = (props) => {

    return (
        <section className="pt-[120px] pb-[20px]">
            <div className="wraper">
                <div className="grid grid-cols-12 items-center gap-3">
                    <div className="col-span-4 md:col-span-12">
                        <div className="relative mb-[20px]">
                            <div className=" relative max-w-[520px] before:absolute before:bg-[#BEB499] before:w-[95.38%] before:h-[75.5%] before:content-[''] before:-z-10 before:left-[-1%] before:top-[-10%] before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
                                <img className="w-full" src={about} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-8 md:col-span-12">
                        <div>
                            <div className="pl-[10px]">
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">A native Coloradan, his background includes a long list of credentials: contractor, technical writer, training curriculum author, forensic expert, world traveler, inventor, educator, and even rodeo competitor (Bull Riding). Rob is the Director of the Metal Roof Advisory Group, Ltd, a consulting firm performing a variety of services for a worldwide clientele since 1989. His professional affiliations include NRCA, ASHRAE, ASCE, CSI, ASTM, IIBEC and others.</p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">He is a lifetime honorary member of the Metal Building Contractors and Erectors Association (MBCEA), the Metal Construction Association (MCA), and a charter inductee to the Metal Construction Hall of Fame. He is or has been a course author and faculty member for the Roofing Industry Educational Institute (RIEI), the Roof Consultants Institute (RCI) and the University of Wisconsin (College of Engineering). His professional travels have taken him to more than 30 countries around the globe.</p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Rob has been a technical editor and author for numerous industry publications. With well over 100 writings, his work has been published in at least 30 different trade journals and periodicals over the last 40 years. His presentations and technical writings have been translated in at least eight languages, and he has participated as a speaker at many shows, conferences, symposia and industry events in multiple countries. His “Metal Roofing From A (Aluminum) to Z (Zinc)” series is a featured download on the MCA website, as are “Metal Roof Design for Cold Climates” and “Lightning and Metal Roofing.” Many other writings have become industry standards and “Best Practice” documents. He has been the top-rated speaker at METALCON, the largest international event for the metal construction industry, for 25 consecutive years and the International Roofing Expo (IRE) for 15.</p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">The scope of Rob’s consulting services includes product development and improvement, technology transfer, architectural design review and forensic investigation and expert testimony. His client list includes the American Iron and Steel Institute, the Metal Building Manufacturers Association, Copper Development Association, U.S. Army Corps of Engineers, US DOD, US Air Force, State of New York OGS, Kohn Pederson Fox, Anheuser-Bush, Georgia Pacific, Philips Electronics,  Federal Express, National Roofing Contractors Association, WR Grace, MBCI, McElroy Metals, Star Building Systems, Centria, Rheinzink America, Umicore, North American Zinc/Aluminum Producers Association, National Coil Coaters Association, National Association of Home Builders, and many others.</p>
                                <p className="text-[#000] text-[16px] leading-[28px] font-normal">In 1992, Rob founded a product innovation and commercialization company called Metal Roof Innovations, Ltd., for the purpose of commercializing patented intellectual property. Since that time, he and his S-5! team have been awarded and commercialized more than 130 U.S. and foreign patents representing truly disruptive innovations. He originated the concept of manufactured seam clamps for metal roofing, enabling penetration-free attachment of rooftop ancillaries. Rob has also innovated the use of seam clamping technology to improve wind uplift performance, in some cases doubling the wind resistance of metal roofing. He and his two sons, Shawn and Dustin also innovated direct attachment of photovoltaic and solar thermal arrays to standing seam metal roofing. Technologies within the metal roofing industry that have become known by the tradenames of RoofFloater™, ColorGard®, Gripper-Fix™ and S-5!® are Haddock inventions. S-5! products have become the standard for roof attachments like snow retention systems, roofwalks, static-line fall protection and photovoltaic mountings and are marketed through distribution networks throughout the world.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;