import React from 'react';

// images
import blogImg1 from "../images/blog/img1.png";
import blogImg2 from "../images/blog/img2.png";
import blogImg3 from "../images/blog/img3.png";
import blogImg4 from "../images/blog/img4.png";
import blogImg5 from "../images/blog/img5.png";
import blogImg6 from "../images/blog/img6.png";
import blogImg7 from "../images/blog/img7.png";
import blogImg8 from "../images/blog/img8.jpeg";
import blogImg9 from "../images/blog/img9.png";
import blogImg10 from "../images/blog/img10.png";
import blogImg11 from "../images/blog/img11.png";
import blogImg13 from "../images/blog/img12.jpg";
import blogImg16 from "../images/blog/img13.jpeg";

import Blog1 from "./blogs/1";
import Blog2 from "./blogs/2";
import Blog3 from "./blogs/3";
import Blog4 from "./blogs/4";
import Blog5 from "./blogs/5";
import Blog6 from "./blogs/6";
import Blog7 from "./blogs/7";
import Blog8 from "./blogs/8";
import Blog9 from "./blogs/9";
import Blog10 from "./blogs/10";
import Blog11 from "./blogs/11";
import Blog12 from "./blogs/12";
import Blog13 from "./blogs/13";
import Blog14 from "./blogs/14";
// import Blog15 from "./blogs/15";
import Blog16 from "./blogs/16";

const blogs = [
    {
        id: '1',
        title: 'The Science behind Snow Retention',
        slug: 'The-Science-behind-Snow-Retention',
        screens: blogImg1,
        create_at: '13 DEC, 21',
        blClass: 'format-standard-image',
        article: <Blog1 />,
        articleLink: 'https://readmetalroofing.com/the-science-behind-snow-retention/'
    },
    {
        id: '2',
        title: 'Snow Management for Metal Rooftops',
        slug: 'Snow-Management-for-Metal-Rooftops',
        screens: blogImg2,
        create_at: '1 JAN,21',
        blClass: 'format-standard-image',
        article: <Blog2 />,
        articleLink: 'https://roofingelementsmagazine.com/snow-management-for-metal-rooftops/'
    },
    {
        id: '3',
        title: 'Practices for Durable Accessory Attachment',
        slug: 'Practices-for-Durable-Accessory-Attachment',
        screens: blogImg3,
        create_at: '1 MAR,21',
        blClass: 'format-standard-image',
        article: <Blog3 />,
        articleLink: 'https://readmetalroofing.com/practices-for-durable-accessory-attachment/'
    },
    {
        id: '4',
        title: 'Specifying Rooftop Attachments for Your Metal Roof',
        slug: 'Specifying-Rooftop-Attachments-for-Your-Metal-Roof',
        screens: blogImg4,
        create_at: '1 OCT,21',
        blClass: 'format-standard-image',
        article: <Blog4 />,
        articleLink: 'https://www.metalarchitecture.com/articles/specifying-rooftop-attachments-for-your-metal-roof/'
    },
    {
        id: '5',
        title: 'Types of Solar Mounting Systems for Metal Roofs',
        slug: 'Types-of-Solar-Mounting-Systems-for-Metal-Roofs',
        screens: blogImg5,
        create_at: '28 JUN,23',
        blClass: 'format-standard-image',
        article: <Blog5 />,
        articleLink: 'https://www.solarfeeds.com/mag/types-of-metal-roofing-solar-mounting-systems/'
    },
    {
        id: '6',
        title: 'As the Inflation Reduction Act Becomes Law, Metal Roofing is Ripe for Growth of Rooftop Solar',
        slug: 'As-the-Inflation-Reduction-Act-Becomes-Law-Metal-Roofing-is-Ripe-for-Growth-of-Rooftop-Solar',
        screens: blogImg6,
        create_at: '21 DEC,22',
        blClass: 'format-standard-image',
        article: <Blog6 />,
        articleLink: 'https://readmetalroofing.com/as-the-inflation-reduction-act-becomes-law-metal-roofing-is-ripe-for-growth-of-rooftop-solar/'
    },
    {
        id: '7',
        title: 'Selecting a Roof Attachment Product',
        slug: 'Selecting-a-Roof-Attachment-Product',
        screens: blogImg7,
        create_at: '1 NOV,22',
        blClass: 'format-standard-image',
        article: <Blog7 />,
        articleLink: 'https://www.metalconstructionnews.com/articles/selecting-a-roof-attachment-product/'
    },
    {
        id: '8',
        title: 'Standing Seam Roof Clamps and Understanding Load Capacities',
        slug: 'Standing-Seam-Roof-Clamps-and-Understanding-Load-Capacities',
        screens: blogImg8,
        create_at: '3 OCT,22',
        blClass: 'format-standard-image',
        article: <Blog8 />,
        articleLink: 'https://www.metalarchitecture.com/articles/standing-seam-roof-clamps-and-understanding-load-capacities/'
    },
    {
        id: '9',
        title: 'METAL ROOFING & PV SOLAR SYSTEMS PART 1',
        slug: 'METAL-ROOFING-&-PV-SOLAR-SYSTEMS-PART-1',
        screens: blogImg9,
        create_at: '1 APR,21',
        blClass: 'format-standard-image',
        article: <Blog9 />,
        articleLink: 'https://www.metalconstruction.org/index.php/online-education/metal-roofing-and-solar-pv-systems--part-1--service-life-comparisons'
    },
    {
        id: '10',
        title: 'METAL ROOFING & PV SOLAR SYSTEMS PART 2',
        slug: 'METAL-ROOFING-&-PV-SOLAR-SYSTEMS-PART-2',
        screens: blogImg10,
        create_at: '1 SEP,21',
        blClass: 'format-standard-image',
        article: <Blog10 />,
        articleLink: 'https://www.metalconstruction.org/index.php/online-education/metal-roofing-and-solar-pv-systems---part-2'
    },
    {
        id: '11',
        title: 'METAL ROOFING & PV SOLAR SYSTEMS PART 3',
        slug: 'METAL-ROOFING-&-PV-SOLAR-SYSTEMS-PART-3',
        screens: blogImg11,
        create_at: '1 SEP,21',
        blClass: 'format-standard-image',
        article: <Blog11 />,
        articleLink: 'https://www.metalconstruction.org/index.php/online-education/metal-roofing-and-solar-pv-systems-part-3--mounting-system-installation'
    },
    {
        id: '12',
        title: "These Days, It's Important To Have A Plan B",
        slug: 'These-Days-Its-Important-To-Have-A-Plan-B',
        screens: blogImg7,
        create_at: '5 JAN,22',
        blClass: 'format-standard-image',
        article: <Blog12 />,
        articleLink: 'https://designandbuildwithmetal.com/columns-blogs/haddock-rob/2022/01/05/these-days-it%27s-important-to-have-a-plan-b'
    },
    {
        id: '13',
        title: 'The rise of metal roofing for commercial applications',
        slug: 'The-rise-of-metal-roofing-for-commercial-applications',
        screens: blogImg13,
        create_at: '30 MAR,22',
        blClass: 'format-standard-image',
        article: <Blog13 />,
        articleLink: 'https://www.constructionspecifier.com/the-rise-of-metal-roofing-for-commercial-applications/'
    },
    {
        id: '14',
        title: 'Roof attachment Systems considerations for your rooftop (closer look)',
        slug: 'Roof-attachment-Systems-considerations-for-your-rooftop-closer-look',
        screens: blogImg7,
        create_at: '1 SEP,23',
        blClass: 'format-standard-image',
        article: <Blog14 />,
        articleLink: 'https://roofingelementsmagazine.com/rooftop-attachment-systems-and-considerations-for-the-rooftop/'
    },
    // {
    //     id: '15',
    //     title: 'Co-Inventor of GALVALUME® Makes Life-long Impression on Metal Construction Industry Colleagues',
    //     slug: 'Co-Inventor-of-GALVALUME-Makes-Life-long-Impression-on-Metal-Construction-Industry-Colleagues',
    //     screens: blogImg15,
    //     create_at: '14 AUG,23',
    //     blClass: 'format-standard-image',
    //     article: <Blog15 />,
    //     articleLink: '' // Link not provided
    // },
    {
        id: '16',
        title: 'Rooftop Attachment Systems and Considerations for The Rooftop',
        slug: 'Rooftop-Attachment-Systems-and-Considerations-for-The-Rooftop',
        screens: blogImg16,
        create_at: '5 SEP,23',
        blClass: 'format-standard-image',
        article: <Blog16 />,
        articleLink: 'https://roofingelementsmagazine.com/rooftop-attachment-systems-and-considerations-for-the-rooftop/'
    }
];

export default blogs;
