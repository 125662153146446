import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">Overview</h3>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">This is the second part of a three-part series on metal roofing and solar PV. Part 1 focused on Solar/Roof Service Life comparisons. Part 2 explains the common types of mounting systems for metal roofs, associated risks and pros/ cons of each. A key theme identified in Part 1 is although both solar PV and metal roofing have inherent individual attributes, there are added synergies when used in conjunction with one another. Some factors and checkpoints to fully avail these synergies when mounting on metal roofs are discussed here.</p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">Glossary of Terms</h3>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>collateral dead load:</strong> The self-weight of permanently installed materials attached to the roof or supporting structure with the exact location unknown at the time of design. The primary difference between collateral dead loads and ordinary dead loads is collateral loads are generally not considered to counteract wind uplift.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>energy density:</strong> The annual average energy produced by the PV array divided by the area it occupies or otherwise makes unusable for solar power generation.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>face-fastened metal roof:</strong> (syn., face-attached, through-fastened, exposed-fastened, exposed-fix, screw-down) A metal roof attached to the structure or deck with fasteners that pierce through the roof panels and are visible on the finished roof. Typical profiling is either trapezoidal rib or corrugated.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>fixed:</strong> a PV mounting system held in place by chemical or mechanical means or some combination of both, in contrast to “ballasted” which relies on gravity load to hold the system in place.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>flush mounted:</strong> a PV mounting system that places modules in the same plane (parallel) to the roof surface but offset from its surface by some dimension.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>optimal sun angle:</strong> the annual mean angle of the module measured in degrees to the earth at which the sun is at a right angle to the module. (The optimal sun angle is equivalent to the latitude of the project).</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>power density:</strong> The rated power output of the PV array divided by the area it occupies or otherwise makes unusable for solar power generation.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>solar zone:</strong> the exact roof area most appropriate to install solar for reasons of sun angle and roof obstructions, shading effects, etc.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>standing seam metal roof:</strong> a metal roof that is attached via concealed clips within its side joints (seams), thus avoiding penetration of the weathering membrane.</p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">Discussion</h3>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>The Metal Roof/PV Solution</strong></p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">The size and geometry of an existing roof are generally predetermined by factors unrelated to the afterthought of PV installation. When establishing the solar zone, not all of the roof may be available to host the PV modules. Because the ideal solar zone size and orientation are limited to some degree on most pre-existing roofs, maximizing output per square foot of available roof to achieve the best life cycle ROI is a major consideration. Conversely, when dealing with new construction that is to be “solarized,” preplanning the solar zone often drives architectural roof design regarding size, slope and orientation. In either case, the competent solar contractor will consider the various factors affecting system sizing and layout such as power density, energy density, site latitude, roof angle, shading effects, wiring and inverter derates, adjusting production to consumption, and so on. Software tools generally known and used by the solar trades are readily available for these nuances.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>Types of Mounting Systems</strong></p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">Solar modules are secured to metal roofs by several methods, all of which are fixed. Modules are generally offset from any roof type by some dimension. This offset allows air flow between the roof surface and the solar module, for lower cell operating temperatures and improved production. The optimal minimum offset dimension has been demonstrated by studies to be 4” (100mm). Beyond this offset dimension, there is no significant increase in module output. Modules may then be flush mounted or tilted to achieve an optimal sun angle. Both methods result in different energy output from a given module or number of modules. Each option has differing roof space, structural engineering factors and cost implications so, initial cost and ROI should be analyzed individually when considering and comparing array options.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">When PV modules were at their highest cost per watt and lower efficiencies, tilted systems were the norm to achieve optimal sun angle and were also demonstrably financially prudent. Array designs were driven primarily by the high cost of the PV module, hence achieving optimal sun angle with added mounting costs was financially sound within cost recovery calculations. Within the last decade, PV module costs have fallen dramatically, with the optimal sun angle unaffecting an increase in power production to sufficiently offset the premium costs of the mounting system. Current trends favor low-cost, flush-mounted systems that facilitate additional module installation, resulting in higher power density (watts/sqft) and less severe structural impact.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">Flush-mounted systems are commonly used on metal roofs with slopes as low as ¼:12 (2º). These “near-flat” PV modules do not achieve the energy density of modules positioned at an incline at optimal sun angle. The reduction in energy production may be as little as 5% in southern areas or as high as 12% in northern areas. However, these “near-flat” roof applications also enable north-facing roof areas to carry arrays with power production only a few percent lower than their south-facing counterpart. Consequently, flush-mounted systems may have the best life-cycle cost on large, commercial low-slope roofs, particularly in southern areas of the U.S.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>Further explanation of the most common application methods are as follows:</strong></p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>Flush- Rail Mounted</strong></p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">As demonstrated in Figure 3, a typical flush rail-mounted system utilizes aluminum or coated light-gauge steel rails mounted above the seams or ribs of a metal roof.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">This method normally orients the rails traversing the seams or ribs of the south-facing metal roof. Most module producers specify that the “grabs” (hold-downs) for the module engage the module along the long dimension, resulting in modules with “portrait” orientation to the roof slope. (See Figure 4) The rail allows neighboring modules to be within an inch or less of each other, sometimes maximizing power density.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">Another advantage to this method is that the roof is replete with ribs or seams, so there is increased module placement flexibility. The downside, when compared with flush-rail mounting, is that module layout may not be quite as space-efficient depending on the particulars of the solar zone. Space loss of 2%-5% compared to flush-rail mounting is not unusual. While not a concern if the desired system size fits in the available solar zone, any loss of power and energy density should be balanced against the rail material and labor cost savings in the financial analysis.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">Figure 8 and Figure 9 show a typical tilted (rack) mounting system used to achieve further incline to the existing roof slope. Other methods of tilting are emerging for more moderate tilt angles. This type of installation improves or maximizes module-level energy density. However, there are a number of negative considerations for these types of installation, including an increase in the initial cost due to the extra materials involved and added collateral dead load of those materials to the roof. The additional cost may not be justified by the increase in efficiency, extending the “payback” period.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">A tilted system intensifies wind loads on the roof and can cause undue snow accumulation due to drifting (aerodynamic shade). These increases can influence both snow and seismic effects and create significant roof design challenges in snow-prone, high seismic and windy coastal areas, especially in applications where the impact of these effects must be considered for the structure as well. Most solar installations are over existing roofs, so there is no guarantee these effects were included in the original building roof design. A design professional should be consulted to determine any impacts due to the addition of a tilted solar array. Depending on the design conditions, cost premium and electric cost escalation assumed, the installation may be cost-justified in some cases.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">It is important when mounting PV on metal roofs to ensure that the integrity of the roof is not compromised and warranties are not jeopardized. Metal roofs maintain their value and longevity for decades, but only if their design integrity is carefully preserved.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">Mounting PV should always be consistent with the design principles of the host roof, which vary according to metal roof type. Further, a PV array on a rooftop is exposed to the environmental forces of wind, snow and earthquakes. These forces on any rooftop surface can be complex, making secure attachments of PV crucial. PV arrays that are not properly designed and installed can become airborne during a wind event and pose a serious threat of injury, personal and property damage and present a life-safety issue. Skilled design, engineering and production of these components is required. A working knowledge of metallurgy, sealant chemistries, metal roof types and other variables are also critical to long-lasting solar/metal roof as a combined asset.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal"><strong>Conclusions</strong></p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">The clear advantage of metal roofs, as previously discussed, is a service life that outlasts the solar PV system. Preservation of the metal roof and PV integrity requires expertise and experience with both elements to design the most effective system that addresses all aspects, from installation to long-term durability. The most critical factors to be discussed in Part 3 are the design, testing, manufacturing, and certifications that accompany the PV mounting system. This next segment will expound on various metal roof types and code and standard requirements. (All performance requirements are not specifically called out in national codes and standards.) “Best practices” for the design, manufacturing and installation of these mounting systems and components as related to metal roofing will also be detailed.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">References:</p>
            <ol className="list-decimal list-inside">
               <li className="text-[#000] text-[16px] leading-[28px] mb-[10px] font-normal">American Society of Civil Engineers; Minimum Design Loads and Associated Criteria for Buildings and Other Structures (ASCE 7-16); 2017.</li>
               <li className="text-[#000] text-[16px] leading-[28px] mb-[10px] font-normal">American Society of Civil Engineers; Commentary to ASCE 7-16, Minimum Design Loads and Associated Criteria for Buildings and Other Structures; 2017, p. 778</li>
            </ol>
         </div>
      </div>

   )
}

export default blogPage;