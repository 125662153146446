import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Practice from '../../components/Practice';
import AboutServices from '../../components/about/aboutServices';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'

const PracticePage = () => {

    return (
        <Fragment>
            <Navbar />
            <Practice />
            <AboutServices />
            <ConsultingArea />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default PracticePage;

