import React from 'react';
import blogs from '../../api/blogs'

const BlogSection = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (

        <section className="bg-[#F2EFE8] pt-[43px] pb-[70px]">
            <div className="wraper">
                <div className="col-span-12 text-center">
                    <div className="mb-[60px]">
                        <h2 className=" text-[24px] md:text-[20px] font-semibold text-[#000000] pb-[20px]
                       relative before:absolute before:content-[''] before:left-[50%] before:bottom-0
                       before:transform before:-translate-x-1/2 before:w-[60px] before:h-[3px] before:bg-[#867662]">Articles and Technical Documentation </h2>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-4">
                    {blogs.slice(0, 3).map((blog, bl) => (
                        <div className="col-span-4 md:col-span-6 col:col-span-12" key={bl}>
                            <div className="mb-[30px] group">
                                <div className="transition ease-in-out duration-300">
                                    <img className="w-full transition ease-in-out duration-300 group-hover:grayscale" src={blog.screens} alt="" />
                                </div>
                                <div className="bg-white pt-[20px] px-[20px] pb-[30px]">
                                    <h3 className="text-[24px] leading-[36px] font-heading-font font-medium mb-[20px]">
                                        <a onClick={ClickHandler} href={blog.articleLink} target="_blank" className="text-[#000] transition ease-in-out duration-300 hover:text-[#867662]" rel="noreferrer">{blog.title}</a>
                                    </h3>
                                    <ul className="flex items-center">
                                        <li className="text-[#867662] relative px-[10px]"> {blog.create_at}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default BlogSection;