import React from 'react'
import rules from '../../images/about/rules.jpg'
import cowboy from '../../images/about/cowboy.jpg'
import haddocks from '../../images/about/haddocks.jpg'


const About = (props) => {

    return (
        <section className="pt-[120px] pb-[20px]">
            <div className="wraper">
                <div className="grid grid-cols-12 items-center gap-3">
                    <div className="col-span-4 md:col-span-12">
                        <div className="relative mb-[20px] mr-[25px]">
                            <div className=" relative max-w-[520px] before:absolute before:bg-[#BEB499] before:w-[105.38%] before:h-[105.5%] before:content-[''] before:-z-10 before:left-[-1%] before:top-[-3%] before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
                                <img className="w-full" src={rules} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-8 md:col-span-12">
                        <div>
                            <div className="pl-[10px]">
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">When my children were young, I posted these seven “Rules of the House” on our refrigerator. These are pretty good rules for anyone anywhere to live by. </p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">As my children grew in learning and understanding, I discovered some other “earmarks” about myself. Here are some of them— in no particular order:</p>
                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Never content with mediocrity!</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">My goal is to be the best at what I do. You can’t be the best at everything. If you try, you will lose focus and drive yourself crazy. But you can be the best at one or two things if you stay focused.</p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Honest and transparent. </b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">I tell the truth. I can’t remember telling a lie since I was about eight. The proof of honesty is transparency. I don’t want people to wonder what I am thinking. I tell them. I have been told I am as transparent as glass. I think that is how it should be. I may hold some cards close to my vest, in privacy, but I don’t slip any up my sleeve. I don’t cheat, and I try to communicate as clearly as possible.</p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Keep my word.</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">It’s number five on the refrigerator rules. Do what you say you will do. My word is my bond. That isn’t an “old school philosophy,” but sadly it is becoming rare. It seems that society, in general, today is missing the boat on this one. People make commitments and break them too casually; something as simple as “I’ll call you back” or showing up for a meeting on time. I understand that sometimes we simply need to be excused when unforeseen things happen, but it’s up to me, under that circumstance, to ask to be excused from a commitment ahead of time; then I re-commit immediately.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 items-center gap-3">
                    <div className="col-span-8 md:col-span-12 pr-[50px]">
                        <div>
                            <div className="pl-[10px]">
                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Check the facts.</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">I give no credence to “he said, she said” kinds of stories, rumors and gossip. I go to credible sources and check the truth. Sometimes my wife, Robyn, does that for me. She is the world’s best fact-checker.</p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Ask questions.</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Most of what I know I learned this way. I find that the best education is free. When I don’t fully understand something, I go to respected experts in any given field and ask questions until the answers make sense to me. I do the same in personal relationships. Rather than making assumptions, I ask for clarity.</p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Associate with people I respect and admire.</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">This goes hand-in-glove with asking questions.</p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Strive for perfection.</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">I also recognize that I cannot achieve it. There is only one individual in history who was perfect. But striving for that unreachable goal puts me on the path of excellence.</p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Protect my reputation.</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">I have found that a person’s reputation follows them quickly and for a long time; this is true whether good or bad. The only difference is that a bad one follows them faster and longer.</p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Protect what I own.</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Whether it is my home, my personal possessions, my intellectual property, my life, my health—or anything else, I protect it. Unfortunately, there are thieves, thugs, pretenders, riffraff and con artists everywhere. They are devoid of conscience or morality. Sad to say, but the world is full of them. </p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Own my mistakes.</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Everyone makes mistakes. It is only human. I try to make my mistakes right—no matter the cost. And I always try to learn something from them. I have learned more from my mistakes than I have from getting it right. I used to have trouble forgiving mistakes—and even more difficulty forgiving myself for my own mistakes. Eventually, I learned how easy it really is. I learned this from the Master of forgiveness.</p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Always have “Plan B” </b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Everyone knows about Murphy’s Law (If something can go wrong, it will go wrong.) Most things can go wrong. Having a “Plan B” in my hip pocket and ready to execute it is how I cheat Murphy out of his due. </p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>The Right Way</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Short-cutting and halfway just doesn’t really cut it. In the long haul, it costs more time, trouble, money and stress.</p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Integrity</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Integrity is more than being honest. It is a combination of all these things. I value it above all and mine is not for sale at any price. </p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Diligence and perseverance</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">There is no substitute for hard work and tenacity. I don’t know anyone who has achieved real success working 40 hours per week, or who gives up easily.</p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Build a team.</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Whether it is a family unit or a business enterprise, I can’t do it alone. It takes teamwork and collaboration. Other people are better at certain things. I need those people on my team, and we all need to be pulling in the same direction. I have to make sure that happens.</p>

                                <p className="text-[#000] text-[16px] leading-[28px] font-normal"><b>Put God first.</b></p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">I saved the first and best for last. He actually wrote (or spoke) all the foregoing precepts. I have this reminder on the door of my office. Just thought I’d share it with you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4 md:col-span-12">
                        <div className="relative mb-[20px]">
                            <div className=" relative max-w-[520px] before:absolute before:bg-[#BEB499] before:w-[104.48%] before:h-[90.5%] before:content-[''] before:-z-10 before:left-[-3%] before:top-[-4%] before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
                                <img className="w-full" src={haddocks} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 items-center gap-3">
                    <div className="col-span-3 md:col-span-12 pr-[50px]">
                    </div>
                    <div className="col-span-6 md:col-span-12">
                        <div className="relative mb-[20px]">
                            <div className=" relative max-w-[520px] before:absolute before:bg-[#BEB499] before:w-[104.48%] before:h-[90.5%] before:content-[''] before:-z-10 before:left-[-3%] before:top-[-4%] before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
                                <img className="w-full" src={cowboy} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3 md:col-span-12 pr-[50px]">
                    </div>
                </div>
            </div >
        </section >
    )
}

export default About;