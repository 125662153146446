import React, { useEffect } from "react";

const HubspotContactForm = () => {
   useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);

      script.addEventListener('load', () => {
         // @TS-ignore
         if (window.hbspt) {
            // @TS-ignore
            window.hbspt.forms.create({
               region: 'na1',
               portalId: '3482954',
               formId: '15036ead-6535-4966-acf4-ee021934233b',
               target: '#hubspotForm'
            })
         }
      });
   }, []);

   return (
      <div>
         <div id="hubspotForm"></div>
      </div>
   );

}

export default HubspotContactForm;