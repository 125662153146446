import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Testimonial = (props) => {

    var settings = {
        dots: false,
        arrows: true,
        speed: 1000,
        autoplaySpeed: 8000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    const testimonial = [
        {
            Des: "Rob leads you down the path to understanding with such humor and simple clarity, you not only go away understanding, but remembering!”",
            Title: 'Darrell T. Blair',
            Sub: "Architect",
        },
        {
            Des: "Rob was excellent and his subject very informative.  This type of course is very beneficial to the industry.”",
            Title: 'James C. Ufaihr',
            Sub: "Architect",
        },
        {
            Des: "Haddock is very knowledgeable and makes his point clearly. The course material was great and the instructor was phenomenal. This has been the most comprehensive seminar of my experience, and I have attended approximately100 in the last 30 years.”",
            Title: 'Harold Seckinger',
            Sub: "Architect",
        },
        {
            Des: "This is the best seminar I have attended for years!”",
            Title: 'Beth Trautwein',
            Sub: "Architect",
        },
        {
            Des: "The presenter was fun and knowledgeable; I would gladly attend “Understanding Metal Roofing II” …best I have attended in years; I could have stayed another hour!”",
            Title: 'P.B. Camuso',
            Sub: "Architect",
        },
        {
            Des: "The best feature of this class was Rob’s folksy and professional knowledge combination.  I wish it were a whole day or more!	The PowerPoint presentation had excellent close- up shots of actual details and animation.”",
            Title: 'Jeffrey R. Lurie',
            Sub: "Architect",
        },
        {
            Des: "The history lesson was great and handouts are an excellent resource. The information presented was invaluable!”",
            Title: 'James C. Weeks',
            Sub: "",
        },
        {
            Des: "A lot of information covered in a short time.  It was superbly presented.”",
            Title: 'John Sneils',
            Sub: "Estimator, Sutter Roofing Co. of Florida",
        },
        {
            Des: "Rob answered all questions brought forth during each subject matter and gave examples to help better understand.”",
            Title: 'Ray McCarson',
            Sub: "Roofing Contractor",
        },
        {
            Des: "Outstandingly informative! Excellent presentation, great delivery and a lot of useful information.”",
            Title: 'Joe Byrne',
            Sub: "Byrne Roofing, Inc.",
        },
        {
            Des: "Very knowledgeable instructor—great class!  I always enjoy seminars by Rob! The water intrusion and detailed explanations/ diagrams have been enormously helpful”",
            Title: 'Justin',
            Sub: "Trachte Building Systems, Sun Prairie, WI",
        },
        {
            Des: "The most informative and entertaining presentation on metal roofs you will ever see. Simply put, if you own, build, design or inspect metal roofs you need to see this class!”",
            Title: 'John',
            Sub: "Northland Consulting Engineers, Duluth, MN",
        },
        {
            Des: "Your next MUST do training IF you want to be a metal roof installer with any integrity! I attended Rob Haddock's two-part course on Understanding Metal Roofing at IRE, and I wish I had brought my foreman along with me. Rob's course was easy to understand and even easier to begin implementing his strategies for sound metal roofs. Rob’s teaching style is genuine and the content essential for any contractor installing metal roofing. In fact, I plan to sign my foreman up for his two-day course to further my team’s education.”",
            Title: 'Sarah Lechowich',
            Sub: "Founder True North Roofing, LLC, College Grove, MN",
        },
        {
            Des: "I found Rob’s presentation to be both entertaining and informative.  I’m the senior QC Manager for a multi-billion dollar general contractor that self-performs more than 95% of all work.  The information provided was easily translatable to each of my departments from bid, to placement, to QC and through warranty.  I also found the information useful to help shore up my understanding of metal roofing for my RRO.  I’d look for this class again in the future if Rob is presenting.”",
            Title: 'Brian Tiehen',
            Sub: "Senior Quality Manager,BL Harbert International, Birmingham, AL",
        },
        {
            Des: "Very informative presentation. I particularly liked the history of metal roofing and also the way he debunked myths and mistruths in the industry. The right mix of facts and humor made for a great presentation. I enjoyed it all. Thank you!”",
            Title: 'Bryan Stewart',
            Sub: "RRO - Registered Roof Observer, BC Building Science",
        },
        {
            Des: "The professional experience in the industry Rob has is admirable”",
            Title: 'Mohammed Hassan',
            Sub: "Mighty Moe Gutters & Exterior",
        },
        {
            Des: "Good presentation, answered questions, very informative. It was nice to meet others as well”",
            Title: 'Eugene Gee',
            Sub: "ATBEST Inc.",
        },
        {
            Des: "Informative, good value. Impressed with the printed materials to take home.”",
            Title: 'Tristan Slocum',
            Sub: "Blue Heron Gutters",
        },
        {
            Des: "Rob has an incredible depth of understanding with the material.”",
            Title: 'Brian Crouser',
            Sub: "RCABC - Roofing Contractors Association of British Columbia",
        },
        {
            Des: "Rob is a great presenter. He makes the course feel relaxed, with the information easy to retain.”",
            Title: 'Jesse Hembrough',
            Sub: "Sheet Metal Supervisor, Mack Kirk Roofing & Sheet Metal",
        },
    ]
    return (

        <section className={`relative ${props.tClass}`}>
            <div className="wraper">
                <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-6 col-start-4 md:col-span-8 md:col-start-2 sm:col-span-10 sm:col-start-2 transform -translate-x-1/2 left-[50%] relative mb-[80px] mt-[15px]">
                        <div className="testimonial-text ml-[95px] mt-[50px] md:ml-0">
                            <div className="text-left sm:text-center">
                                <span className="text-[22px] text-[#867662] leading-[22px]">What People Say</span>
                                <h2 className="text-[36px]  md:text-[26px] font-medium text-[#333] pb-[20px] relative leading-[28px] before:absolute before:content-[''] before:left-[-68px] before:top-[30px] before:transform before:-translate-y-1/2 before:w-[60px] before:h-[1px] before:bg-[#867662] md:before:hidden">Client Testimonial</h2>
                            </div>
                            <div className="testimonial-slide owl-carousel ml-[-45px] sm:ml-[0]">
                                <Slider {...settings}>
                                    {testimonial.map((tesmnl, tsm) => (
                                        <div className="slide-item sm:text-center" key={tsm}>
                                            <p className="relative pt-[30px] sm:p-0 z-10 sm:pl-[20px] pl-[43px] pb-[30px] pr-[50px] mb-[20px] before:absolute before:left-[4px] before:top-[5px] before:content-['\f10d'] before:-z-10  before:font-['FontAwesome'] before:text-[#867662] before:text-[30px] sm:before:text-[20px]">{tesmnl.Des}</p>
                                            <div className="mt-[35px] sm:mt-[20px] sm:mb-[30px] text-center">
                                                <h4 className="text-[20x] font-semibold font-base-font">{tesmnl.Title}</h4>
                                                <span className="text-[20px] text-[#867662]">{tesmnl.Sub}</span>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;