import React from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import Logo from '../../images/MRAG.png'


const Header = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <header className=" relative z-[111]">
            <div className="wpo-site-header bg-[#ffffff] relative ">
                <div className="wraper">
                    <div className="flex items-center justify-between ">
                        <MobileMenu />

                        <div className="logo w-[255px] md:w-[200px] md:mx-auto sm:w-[180px] col:w-[160px]">
                            <Link onClick={ClickHandler} className="text-[45px] col:text-[25px] font-bold flex items-center text-white" to="/">
                                <img className="w-full py-[18px]" src={Logo} alt="" /></Link>
                        </div>
                        <ul className="md:hidden">
                            <li className="relative inline-block group">
                                <Link onClick={ClickHandler} to="/" className="relative text-[20px] lg:text-[20px] py-[35px] xl:py-[30px] px-[18px] xl:px-[6px] text-[#182848] hover:text-[#867662] block font-base-font font-bold hover:text-[#867662] transition-all
                                ">Home</Link>
                            </li>
                            <li className="relative inline-block">
                                <Link onClick={ClickHandler} to="/about" className="relative group text-[20px] lg:text-[20px] py-[35px] xl:py-[30px] px-[18px] xl:px-[6px] text-[#182848] block font-base-font font-bold hover:text-[#867662] transition-all
                               ">About</Link>
                            </li>
                            <li className="relative inline-block">
                                <Link onClick={ClickHandler} to="/services" className="relative group text-[20px] lg:text-[20px] py-[35px] xl:py-[30px] px-[18px] xl:px-[6px] text-[#182848] block font-base-font font-bold hover:text-[#867662] transition-all
                               ">Services</Link>
                            </li>
                            <li className="relative inline-block">
                                <Link onClick={ClickHandler} to="/articles" className="relative group text-[20px] lg:text-[20px] py-[35px] xl:py-[30px] px-[18px] xl:px-[6px] text-[#182848] block font-base-font font-bold hover:text-[#867662] transition-all
                               ">Articles</Link>
                            </li>
                            <li className="relative inline-block">
                                <Link onClick={ClickHandler} to="/#contact" className="relative group text-[20px] lg:text-[20px] py-[35px] xl:py-[30px] px-[18px] xl:px-[6px] text-[#182848] block font-base-font font-bold hover:text-[#867662] transition-all
                               ">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;