import React from 'react';
import blogs from '../../api/blogs';
import { useParams } from 'react-router-dom'

const BlogSingle = (props) => {

    const { slug } = useParams()

    const BlogDetails = blogs.find(item => item.slug === slug)

    return (
        <section className="py-[90px] md:py-[70px] sm:py-[60px] bg-[#e4e0d4]">
            <div className="wraper">
                <div className={`${props.gClass} grid gap-3`}>
                    <div className={`col-span-12 md:col-span-12 ${props.blRight}`}>
                        <div className="">
                            <div className="mb-[70px]">
                                <h1 className="text-[34px] md:text-[25px] sm:text-[22px] text-[#282e3f] font-normal font-heading-font leading-[40px] transition-all mb-[20px] group">{BlogDetails.title}</h1>
                                <div className="overflow-hidden mt-[5px] mb-[35px]">
                                    <ul>
                                        <li className="text-[20px] font-medium float-left col:float-none col:block col:ml-[0px] col:mb-[5px] text-[#000]"><i className="relative top-0 mr-[3px] text-[15px] text-[#000]  fi flaticon-user"></i> By: <b className="text-normal" to="/blog-single/Car-Accidents-Caused-by-Defective-Vehicles">Rob Haddock</b> </li>
                                        <li className="text-[20px] font-medium float-left col:float-none col:block col:ml-[0px] col:mb-[5px] text-[#666] ml-[20px] pl-[20px] relative before:absolute before:left-0 before:top-[50%] before:w-[2px] before:h-[15px]  before:content-[''] before:translate before:-translate-y-1/2 before:bg-[#ccc] z-10 before:-z-10"><i className="relative top-0 mr-[3px] text-[15px] text-[#666]  fi flaticon-calendar-1"></i> {BlogDetails.create_at}</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {BlogDetails.article}
            </div>
        </section>
    )

}

export default BlogSingle;
