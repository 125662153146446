import React, { Fragment } from 'react';
import AboutArticles from '../../components/about/aboutArticles'
import Navbar from '../../components/Navbar/Navbar';
import BlogSection from '../../components/BlogSection/BlogSectionPage';
import Testimonial from '../../components/Testimonial/Testimonial';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'

const BlogPage = () => {
    return (
        <Fragment>
            <Navbar />
            <AboutArticles />
            <BlogSection />
            <Testimonial />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default BlogPage;