import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/hero/hero';
import About from '../../components/about/aboutHP';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Practice from '../../components/Practice';
import Testimonial from '../../components/Testimonial/Testimonial';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';
import Attorney from '../../components/attorneys';
import AboutS5 from '../../components/about/aboutS5';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';

const HomePage = () => {
    const location = useLocation();

    useEffect(() => {
        // Parse the URL to check if it contains an anchor
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <Fragment>
            <Navbar />
            <Hero />
            <About />
            <Practice />
            <Testimonial />
            <ConsultingArea />
            <Attorney />
            <AboutS5 />
            <BlogSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage;