import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Within the past four decades, more than 185 hundred million m2 (2 billion sf) of metal roofing have been installed in the United States each year. This number continues to grow year over year and is more than any other commercial roofing material.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The roots and technology of metal as a cladding material date back more than 3000 years. Its use as a roofing material originated in the Middle East with such applications as the Dome of the Rock in Jerusalem circa 690 A.D. and even earlier on Solomon’s Temple circa 960 B.C. The metal roof migrated to Europe during the Crusades (1095-1291). Original structures can still be found all over Europe, the Middle East, and Asia.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal roofing first came to North America with artisans like the well-known Paul Revere, who founded a copper company still bearing his name. It has always been coveted as a premium roofing option but, until more recent history, has been inhibited by higher initial costs than the alternatives (ethylene propylene diene monomer, polyvinyl chloride, or thermoplastic polyolefin). Beginning in the mid-1800s and continuing to present day, production technologies and the use of coated steel and (later) aluminum sheeting began to narrow the premium price chasm.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The lifecycle costs and environmental appeal of metal offer several advantages to current lifecycle trends. As a result, metal is becoming the roofing material of choice for both commercial and residential applications. Today’s roofing trends point toward evaluating the long-term costs of owning and maintaining a roof. For owners and operators, environmental aspects of the industry—pre- and post-construction—have become the primary focus in the materials lifecycle of a roof. Additionally, concerns over landfills becoming overburdened with former building components discarded due to shortsighted budget-conscious building objectives are driving the focus on more sustainable roofing materials.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal is now one of the preferred roofing materials in the residential market niche, second only to asphalt shingle roof and has climbed from less than two percent of the market share to more than 15 within the last three decades. The more dominant use in the non-residential market share of engineered metal buildings as measured by the Metal Building Manufacturers Association (MBMA) fluctuates between 28 and 36 percent of F.W. Dodge Primary Construction. While MBMA construction represents only a portion of the total non-residential metal roofing market share, this figure alone exceeds any other commercial roof type in the industry.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Service life and return on investment (ROI)
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal roofs are known for durability, environmental sustainability, variant styles, and versatility. They are experiencing a surge in popularity because the maintenance requirements and lifecycle ownership costs are substantially lower than the alternatives.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Roofing for commercial construction is generally referenced as “low slope” or “steep slope.” Steep-slope roofs are generally water-shedding (hydrokinetic), and low-slope roofs are generally water-tight (hydrostatic) and, therefore, suitable for use down to 0.25:12 (two percent) slopes. This minimum is no different than code mandated slope requirements for single ply.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The Metal Construction Association (MCA) published an empirical field/data study supported by lab analyses in the low-slope market sector. The study indicates the service life of 55 percent aluminum-zinc (AlZn)-Coated Steel standing seam metal roofing (SSMR) is in the range of 70 years in non-coastal applications. (55 percent AlZn steel is the commercial standard for SSMR). Steep-slope, (also known as “architectural”) coated-steel roofing is expected to have a similar service life except for some pigmented finishes.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Other metals used for roof applications have even greater service lives. Stainless and natural metals such as copper and zinc have service lives well over a century, but these materials are often beyond the reach of most construction budgets. A 70-year service life far exceeds that of non-metal commercial roofing systems, which generally expire around 15 to 20 years (refer to landfill concerns noted above). Additionally, because of the maintenance freedom afforded by metal roofs, owners are not faced with costly roof upkeep, patching, and repair.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Typical maintenance of a properly installed metal roof involves annual gutter cleaning, debris removal, and inspection for problems every five years or so. Semi-annual rinsing is often advised in coastal regions to prolong service life. (“Coastal” is defined within most warranty language as within one-fourth of a mile of breaking surf). Alternative roofing materials with much shorter life spans not only have more intense maintenance requirements but also the cost of repair, tear-off, and replacement offer little advantage over metal products other than initial cost.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Increasingly, building owners are choosing coated steel SSMRs over asphaltic and membrane roofs because the initial cost premium of 20 to 30 percent is a bargain, given SSMRs outlive those alternatives by three to four times.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Sustainability
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The growing demand for durable and environmentally friendly construction materials with reduced maintenance and longer service lives leads commercial designers and owners to metal roofing. It is attractive, energy-efficient, long-lasting, weather-resistant, and easy to maintain.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal roofing is a sustainable material because of its extended service life, low production consumption of natural resources, zero-petroleum byproducts, and recyclable economic prudence. Some roof system vendors make claims of “100 percent recyclability,” but such claims are meaningless if the recycling process is not economically justified or verifiable.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               For instance, an old shoe is “100 percent recyclable” despite the economic consequence (i.e., the cost of recycling versus value once recycled). What is pertinent is “what is the material’s actual recycle rate?” At nearly an 85 percent recycle rate, steel is the most recycled construction material available, second only to copper. This is important to building owners and designers conscious of both environmental and economic efficacies. Old metal roofs rarely end up in landfills, thus saving dwindling landfill space and helping to protect the environment.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal roofing is also resistant to fire, weather, and climate conditions due to its sturdy and inert composition. It is non-flammable, non-combustible, and will not ignite during a wildfire or lightning strike, which may help save on insurance premiums. Metal roofing over open structural framing is a Class A (UL 790) material with respect to burning brand flame spread. Over wood decking with fire retardant underlayment is also accepted by Underwriters Laboratories (UL) and approved as Class A.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Ultraviolet (UV) rays and temperature changes fatigue most roof types with constant thermal expansion and contraction while modern SSMRs respond to temperature changes freely and without fatigue by their design. Metal is inert and impervious to UV degradation. Premium factory finishes of polyvinylidene fluoride (PVDF) paint films offer up to 40-year warranties against excessive fade, chalk, and film integrity. Further, because metal panels have structural characteristics, they can be designed to resist virtually any wind speed, including Category 5 hurricane winds.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               With metal roofing, building owners can potentially save money on their utility bills. Reflective pigments on metal roofs rate the highest in “cool roofing,” providing savings on cooling costs in warmer climates. According to Oak Ridge National Lab studies, metal roofs also retain their reflective characteristics better and longer than other membrane roofing materials which lose up to 50 percent reflectivity within three years and must be washed to be restored. Polyvinyl di-fluoride (PVF2 or PVDF; Kynar/Hylar) paint makeup used on metal roofs makes it a close chemical cousin to polytetrafluoroethelyne PVF4 (Teflon). Dirt and pollutants rarely collect on the surface because it is self-cleaning every rainfall due to a slippery finish chemistry.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Advantages to choosing a standing seam metal roof (SSMR)
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               “Standing Seam” style metal roofing has been in use for more than a millennium on some of the most prestigious buildings in history including Notre Dame Cathedral, Houses of Parliament, and Westminster Abbey in England; the Kronborg (Hamlet) Castle in Helsingor, Denmark; Abe Lincoln’s original law office in Springfield, Ill.; and numerous historical buildings in Washington, D.C., just to name a few.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               SSMR attaches to the building’s structure or deck with concealed clips folded into the standing seams. The clips enable the roof panels to expand and contract freely in response to thermal cycling. This method of fastening means the weathering surface of the finished roof is penetration-free. The challenge is mounting ancillaries to a SSMR without violating those two design principles: movement and keeping the weathering surface free of holes.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Today’s building owners are faced with the need to mount essential rooftop equipment and ancillary mechanicals including HVAC equipment, screens to hide it, piping and conduit to fuel it, scuttles to access it, and walkways to service it. With no clear industry consensus as to the “right way” to mount equipment in years past, such ancillaries were mounted by “hook or crook,” violating roof integrity. Sadly, the exceptional lifespan of a metal roof became compromised when forced to function as a mechanical equipment platform.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Enter disruptive innovations (beginning in 1992) that enable the attachment of virtually anything to a SSMR penetration-free—and without violation of the roof’s thermal response. The mounting of everything from a satellite dish to a photovoltaic (PV) solar array became easy and inexpensive. In fact, it is so expedient and cost-effective, the standing seam roof has become the most user-friendly roof type in the construction industry.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Today’s SSMR is a time-proven choice for both steep and low-slope applications, considered by most to be state-of-the-art in metal roofing alternatives. The weakness of a SSMR has now become the newly found advantage of the most durable and sustainable metal roof: nearly anything can be mounted to the roof penetration-free, without concern (except for structural loading considerations).
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Today, building owners are adding grid-tied solar PV to augment the power required to run their facilities. The financial prospect makes sense, turning cash positive in three to seven years and providing power for decades thereafter.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               With the increasing use of solar on commercial buildings, metal roofing has become a driver for roof type selection in many cases. Costco, FedEx, Whole Foods, Walmart, and others are using solar to power stores, offices, and distribution facilities. Apple Inc.’s widely acclaimed campus in California’s Silicon Valley boasts one of the largest roof-mounted solar arrays in the world at 7.4 MW—all of it installed over a SSMR.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               According to a Berkeley study, the service life of solar PV is between 28 and 37 years, with an average of 32.5. Most alternative roofing systems expire long before the life of the PV system. This leads to costly disassembly of the PV array, re-roofing, and re-assembly. A SSMR provides an ideal platform and is the only commercial roof type featuring a service life that exceeds the solar PV system.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               It is also easier and less expensive to mount solar to a metal roof than any other roof type. In most cases, this cost savings is even sufficient to offset the premium initial cost of a SSMR. Solar PV can be mounted to the standing seams of the roof with reliable tested and engineered mechanical attachment methods. Also note: building design for loading must account for the additional 2.0 to 2.5 psf collateral load presented by the PV array.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Designers should know something about roofing alternatives and service lives to bring added value to their clients. With the cost of solar decreasing over the last decade, federal and local incentives, as well as public policy mandates driving the popularity of solar, the numbers improve every year. When solar PV is incorporated in building design, the SSMR makes sense from a financial and ecological perspective.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Owner decisions: Service life and return on investment (ROI)
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal roofing is known for its durability, environmental sustainability, variant styles, versatility, and material longevity. As a trusted roofing material for centuries, metal roofs are experiencing a surge in popularity because more and more owners and designers are realizing the maintenance and lifecycle costs are substantially lower than alternatives roofing materials. Among the many benefits of metal roofing is the ease of mounting rooftop ancillaries, which makes this roof type worthy of serious consideration for owners and designers within the investment discussions surrounding present and future building use and objectives. The attributes of today’s standing seam metal roof are especially poignant in this dynamic era when rooftop solar is a growing driver for building design—whether incorporated in the initial design phase or future-proofed for solar technology and other potential retrofits.
            </p>
         </div>
      </div>

   )
}

export default blogPage;