import React from 'react';
import Practices from '../../api/Practices';


const Practice = (props) => {

    return (

        <section className="bg-[#F2EFE8] pt-[24px] pb-[70px]">
            <div className="wraper">
                <div className="col-span-12 text-center">
                    <div className="mb-[60px]">
                        <h2 className="text-[#000] text-[20px] font-bold relative pb-[8px] before:absolute before:content-[''] before:bottom-0 before:left-[50%] before:bottom-0 before:transform before:-translate-x-1/2 before:w-[77px] before:h-[3px] before:bg-[#999999] before:rounded-full before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">Services Offered</h2>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-3">
                    {Practices.map((practice, Pitem) => (
                        <div className="col-span-4 md:col-span-6 sm:col-span-12 mb-5" key={Pitem}>
                            <div className="group flex sm:mx-[80px] col:mx-0">
                                <div className="h-[80px] w-[80px]">
                                    <div className="h-[80px] w-[80px] leading-[75px] border border-[rgba(192, 181, 150, .5)] rounded-[50%] text-center">
                                        <img className="w-full" src={practice.sImg} alt="" />
                                    </div>
                                </div>
                                <div className="pl-[24px]">
                                    <h3 className="text-[#373737] text-[24px] col:text-[20px] font-medium pb-[10px] relative mb-[10px]">{practice.sTitle}</h3>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}


export default Practice;