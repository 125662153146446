import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Year-after-year, rooftop avalanches cause hundreds of millions of dollars in property damage, personal injury, and even death. Snowpack can suddenly release and dump tons of snow below the eaves in a matter of seconds—endangering building elements, adjacent roofs, landscaping, vehicles, property, and, even worse, pedestrians. Inadequate snow guard systems (or none at all) create a life-safety issue and potential liability for building owners, designers, and contractors.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The best way to mitigate this avalanche danger on a metal roof and reduce liability caused by sliding snow and ice is by installing a scientifically tested and engineered snow retention system specific to the snow loads expected on the particular metal roof profile.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               A snow guard is a device or system that mechanically provides a frictional interface between the roof and the snow, so that snow evacuates the roof in a predictable and controlled fashion, rather than by a sudden and dangerous rooftop avalanche.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Understanding the Math & Science
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The forces of snow on your rooftop can be mathematically calculated, and should be for any snow guard system. The service loads applied to a snow guard system are a relatively simple calculation, varying with site specifics, all of which should be known to the design team: 1. the design roof snow load, 2. roof slope, and 3. roof length (eave-to-ridge). These three factors determine the vector force that a system must resist for any roof surface and should be included in plans and specifications requiring an engineered system.
            </p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Snow Retention System Design Considerations
            </h3>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Snow Retention Population and Placement: Snowbanks typically accumulate and densify in a cross-sectional wedge pattern. All snow guards rely on the snow’s own compressive strength to restrain its movement. Gravitational forces compress the snowbank mostly at its interface with the roof surface, especially toward its lower (eave) end—so this is where compressive strength is greatest. The interface of snow retention devices at this location has proven to be strongly preferred worldwide and considered to be most effective.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               System Design: Two snow guard system designs are quite common. One utilizes continuous horizontal components, assembled laterally across the roof in the style of a “fence.” Such assemblies are usually installed at or near the eaves. Depending on specific job conditions and load-to-failure characteristics of the devices, they may also be repeated in parallel rows up the slope of the roof, but with greater concentration near the eave area.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The other design consists of small, discontinuous individual units used as “cleats,” generally spot-located at or near the eave or repeated in a pattern progressing up the slope of the roof, again with a greater concentration near the eaves. This style also relies upon the shear strength within a snowbank to “bridge” between the individual units.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Snow Retention mounting Techniques
            </p>
            <ul className="list-disc list-inside pl-[20px]">
               <li className="mb-[10px]">Clamping a Snow Guard to a Standing Seam Type Roof: This method uses a seam-clamping attachment, and is a secure option for a standing seam metal roof profile. Clamps attach directly to the roof seam using setscrews that do not penetrate the roof (mechanically-attached).</li>
               <li className="mb-[10px]">Attaching to the Building Structure Itself: The second method for mounting snow retention is a system that attaches snow guards through the roof and into the building structure (also a mechanical attachment).</li>
               <li className="mb-[10px]">Using a Stick-On Guard: The third type of snow retention is a stick-on (adhered) part (a chemical attachment).</li>
            </ul>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               How to Choose the Best Snow Retention System for Your Project
            </h3>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Numerous snow guard vendors have appeared in the market, each claiming to be the best, ultimate, first, or strongest. There are no industry standards or mandates for design, manufacturing, use, or testing of snow guards—no “snow guard police”—so this market space is completely unregulated. It is a “buyer-beware” scenario as to the appropriateness and proof of testing and engineering performed by the vendor.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Vet and Specify: Manufacturer transparency is requisite to adequately vet a snow guard system.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Proof of Testing: Anchorage of clamps to the roof specimen should be repetitiously tested on the specific material, gauge, and roof profile.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Proof of Engineering: Project-specific engineering should be provided by the vendor and incorporate the tested strength of the system with an appropriate factor of safety applied.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Proof of Certified Manufacturing: How can you know the product tested is really the product purchased?
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Warranties: Does the manufacturer offer meaningful product defect and performance warranties?
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Why Use Snow Retention?
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal roofs are known for their durability, sustainability, and versatility, but are slippery and can pose potential of rooftop avalanches in the discharge areas below the eaves, causing property damage, personal injury, and even death.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               A tested and engineered snow retention system increases the friction between the roof and the snow, so the buildup of snow and ice on a roof evacuates in a predictable and controlled fashion rather than by a sudden release of sliding snow.
            </p>
         </div>
      </div>

   )
}

export default blogPage;