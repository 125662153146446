import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Many information sources are available concerning basic and advanced technologies of why and how photovoltaic (PV) works. This white paper does not expound on those subjects but focuses on a commonly overlooked topic: Durability, longevity and mounting platform compatibility of these systems to the roof. PV power generation is not only environmentally friendly, but also a prudent economic investment for both residential and commercial rooftop electric power. In the continental U.S., the rooftop solar investment typically has simple payback periods of 3-7 years and continues to produce power for another 25 years or more thereafter. However, mounting PV to a roof with a service life shorter than the PV array may not make financial sense.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal roofing has inherent aspects that make it compelling as the perfect platform for mounting a PV system including geometry, strength, sustainability and durability to wind, hail, rain and snow. First and foremost, metal is highly inert and service life is measured not in years, but decades. Given that solar PV has a service life of more than 30 years and a price tag far greater than any roof to which it is mounted, this factor alone drives the sensible roof choice to metal.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal is the only roof type that outlives the solar array with the sole exception of natural slate (which is only suitable for steep-slope applications and is 5-10 times the cost of metal). Mounting PV on most other roof types will require roof replacement at the mid-life point of the PV life cycle. Replacement involves removal of the PV array and re-installation after roof replacement is completed – possibly even more than once.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal roofing is available in many different colors, thicknesses and profiles, some of which are better suited to PV mounting than others. Standing seam profiles offer a significant advantage by providing a penetration-free method of the array attachment to roof surface. Such profiles enable PV attachment in ways that preserve the design intent – zero penetration of the roof, thus preserving weather integrity and roof material warranties throughout the PV service life and beyond.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Durability
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal roofing, regardless of the metal finish, is known for low-maintenance and long service life. Painted metal roof systems often carry warranties of 40 years or more, and with proper installation, are not voided by the application of a solar PV array. Metal roofing paint systems are generally factory-applied, baked-on coatings, applied in a continuous process before the metal is formed into a profile. The paint film is stretched and compressed through this fabrication process, so it is certainly durable. Unpainted (or “bare”) finishes may actually last longer than pre-painted in many applications, not being limited by the environmental degradation by moisture and UV exposure. This is all important to understand in order to ensure the roof life exceeds the expected life of the PV system. Available metals include coated steel, aluminum, zinc, copper and stainless.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Service Life
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The service life of metal roofing is among the longest of all roofing systems due in part to the durability of the substrate (and paint) and the long-lasting sealants, fasteners, and trims. A recent study published by the Metal Construction Association found that the service life of a properly designed and constructed metallic coated steel roof is in the range of 70 years in most non-coastal U.S environments.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Premium metal roofs require little, if any, maintenance beyond periodic inspections for anomalies, such as blocked drainage from fallout materials. PV systems should be regularly inspected, making simultaneous metal roofing inspection easy for these environmental effects.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               According to a recent Berkeley study, the statistical average life of a solar PV array is 32.5 years, ranging up to several years more. This is acutely important because it makes little sense to install a 30 or 40-year asset, such as a solar PV array on a roof not expected to last at least as long. Few roof types meet this scrutiny – even when new.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Sustainability
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               When considering the core reasons for creating energy from renewable sources, like solar PV, the goal is about reducing the use of fossil fuels. Solar generation is eco-friendly, producing clean energy and reducing CO2 emissions globally. Metal roofing is aligned with these goals. Steel is not only 100% recyclable but is also the most recycled construction material in the world at nearly 85%. The actual recycle rate is a testament to the fact that recyclability is cost-effective and sustainable in practice. Recycled steel uses less energy and water than production of new steel. Couple this with the longer service life of steel, you rarely find metal roof in a landfill, whereas other roof materials comprise 7-10% of landfill material.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Solar Total Installed Cost
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Installing PV on metal roofing is less expensive than other roof types. These cost savings are driven by simpler, lower cost mounting systems and quicker installation time – less time on the roof means less disruption and lower labor costs. Spending a few more dollars for a metal roof could result in 10-15% savings on the solar PV system installation. On larger commercial projects, the economies of scale further increase the savings. The “life-cycle” savings of solar on metal roofs, further enhances these economies.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Life-Cycle Cost
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               While metal roofing may be initially higher in cost than alternative roofing options, the value proposition of metal is most cost-compelling when evaluated using life-cycle cost. The primary reasons are: 1) Low roof maintenance cost and 2) Extended roof service life over which the initial cost is spread.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               When an array is mounted to any roof type, there are factors that can improve, or greatly diminish, the value proposition of PV. In considering an investment like solar generation, a major factor is the lifespan of the roof supporting the solar PV. Metal is the only roof type that offers the benefit of outlasting the PV.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">[Installation systems will be discussed in greater detail in Part 2 of this series.]</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">The greatest life-cycle benefit of installing solar on metal roofs, however, is the cost avoidance of inevitable replacement of alternative roof types. For instance, common asphalt shingles (steep slope) and [thermoplastics] single-ply membranes (low slope) have much shorter service lives, therefore relative certainty that the useful life of the roof will expire long before the solar PV array. Figure 1 shows the expected mean service lives of common roofing materials based on survey of the Roof Consultants Institute (2005)6 and the Metal Construction Association “Service Life Assessment” (2014)1.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">The cost of roof replacement at that time will include the labor costs of dismantling and reinstalling the solar PV array following roof replacement. The cost of lost electricity production while the system is down and replacement of wiring and any damaged components during the process must be added to this. The aggregated sum of those costs can approach the original cost of the solar system. For this reason, it is well-advised to consider installing a new roof on roof types other than metal before solar is mounted, even if the existing roof is only at 50% or less of its useful life.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">Since the service life of a premium metal roof far exceeds that of the PV array, there is little concern when mounting solar on that roof – even when aged 15-30 years. The probability of re-roofing or other costly repairs 10 or 15 years later when the solar system is still functional is eliminated. Of course, it is only prudent that aged metal roofs are also deserving of some scrutiny regarding their remaining service life prior to making a final decision on the installation of a solar array.</p>
            <ul className="list-disc list-inside pl-[20px]">
               <li className="mb-[10px]">Coated Steel</li>
               <li className="mb-[10px]">Concrete Tile</li>
               <li className="mb-[10px]">EPDM</li>
               <li className="mb-[10px]">PVC - Reinforced</li>
               <li className="mb-[10px]">BUR - Asphalt Glass Felt Plies</li>
               <li className="mb-[10px]">APP Modified Bitumen</li>
               <li className="mb-[10px]">SBS Modified Bitumen</li>
               <li className="mb-[10px]">TPO - Reinforced</li>
               <li className="mb-[10px]">Asphalt Shingles - Glass Felt - Laminated</li>
               <li className="mb-[10px]">Asphalt Shingles - Glass Felt - 3 Tab</li>
            </ul>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">To accurately assess the real cost and return on a solar investment, one needs only to look at both the initial costs and the ongoing O&M (life cycle) costs of both the roof and solar together throughout the expected service life of the solar PV system along with prudent roof replacement costs as appropriate. Solar contractors utilize commercially available software applications to predict long-range costs and return on investment (ROI) of the solar side of this equation but rarely if ever involve the roof-life and replacement consideration.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">Typical financial proforma (e.g., economic analysis) for solar PV begins with the initial hard-cost side of the equation. Investment credits and other incentives from federal, state and local utility sources are also taken into consideration. The cost-recovery factors the output of the solar system in kilowatt-hours using historical and site-specific “sun-hour” data. Other variables include current actual costs of electricity, projected future rate increases, inverter replacement cost, routine maintenance, and output de-ratings from efficiency losses initially and also over time. There are few (if any) such programs that consider roof replacement costs at the mid-life of the PV system. Most solar installers know little concerning roof service life or replacement costs. Software programs employed assume that the roof will last forever – but it doesn’t.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">Because roof and solar functions are interdependent, they together should be considered as a single asset within the financial analysis. The financial analysis offered at the point of sale presumes the roof will last for the entire life expectancy of the solar, yet this is often a mistaken assumption. With such an analysis, systems become cash-positive within the first seven years of operation and show internal rates of return in the range of 15-40% over a 30-year period. Similarly, solar lease-back programs are cash positive from day one. But such an analysis is only valid if the roof outlasts the solar. Most roofs do not.</p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">Solar is a financially prudent investment – provided roof replacement is not necessary.</p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">References</h3>
            <ol className="list-decimal list-inside pl-[20px]">
               <li className="mb-[10px]">Dutton, Ron; Howard, Charles; Haddock, Robert; Kriner, Scott; Service Life Assessment of Low-Slope Unpainted 55%Al-Zn Alloy-Coated Steel Standing Seam Metal Roof Systems, Metal Construction Association; October 18th, 2014.</li>
               <li className="mb-[10px]">Wiser, Ryan H.; Bolinger, Mark; Seel, Joachim; Benchmarking Utility-Scale PV Operational Expenses and Project Lifetimes: Results from a Survey of U.S. Solar Industry Professionals; Lawrence Berkeley National Laboratories; June, 2020.</li>
               <li className="mb-[10px]">Steel Recycling Institute, Steel Takes the LEED with Recycled Content; American Iron and Steel Institute; August, 2009.</li>
               <li className="mb-[10px]">U.S. Army Corps of Engineers; Recycling Exterior Building Finish Materials; Public Works Technical Bulletin 200-1-44; February 1, 2007.</li>
               <li className="mb-[10px]">Cash, Carl G.; 2005 Low-sloped and Steep Sloped Roofing Industry Survey; Simpson Gumpertz & Heger Inc.; May, 2005.</li>
            </ol>
         </div>
      </div>

   )
}

export default blogPage;