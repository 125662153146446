import atimg1 from '../images/expert/asce_logo.svg'
import atimg2 from '../images/expert/ashrea_logo.svg'
import atimg3 from '../images/expert/astm_logo.svg'
import atimg4 from '../images/expert/csi_logo.svg'
import atimg5 from '../images/expert/mbcea_logo.svg'
import atimg6 from '../images/expert/mbma_logo.svg'
import atimg7 from '../images/expert/mca_logo.svg'
import atimg8 from '../images/expert/mra_logo.svg'
import atimg9 from '../images/expert/nrca_logo.svg'
import atimg10 from '../images/expert/iibec_logo.svg'


const Attorneys = [
   {
      Id: '1',
      AtImg: atimg1,
      name: 'American Society of Civil Engineering ',
   },
   {
      Id: '2',
      AtImg: atimg2,
      name: 'The American Society of Heating, Refrigerating and Air-Conditioning Engineers',
   },
   {
      Id: '3',
      AtImg: atimg3,
      name: 'American Society for Testing and Material',
   },
   {
      Id: '4',
      AtImg: atimg4,
      name: 'Construction Specifications Institute ',
   },
   {
      Id: '5',
      AtImg: atimg5,
      name: 'Metal Building Contractors and Erectors Association',
   },
   {
      Id: '6',
      AtImg: atimg6,
      name: 'Metal Building Manufacturer Association (Associate Member)',
   },
   {
      Id: '7',
      AtImg: atimg7,
      name: 'Metal Construction Association',
   },
   {
      Id: '8',
      AtImg: atimg8,
      name: 'Metal Roofing Alliance',
   },
   {
      Id: '9',
      AtImg: atimg9,
      name: 'National Roofing Contractors Association',
   },
   {
      Id: '10',
      AtImg: atimg10,
      name: 'International Institute of Building Enclosure Consultants',
   }

]

export default Attorneys;