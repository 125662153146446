import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import About from '../../components/about/about';
import Principles from '../../components/about/Principles';
import Navbar from '../../components/Navbar/Navbar';
import Awards from '../../components/attorneys/Awards';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'

const AboutPage = () => {
    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'About Rob Haddock'} />
            <About />
            <Awards />
            <Principles />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;

