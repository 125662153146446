import React from 'react';
import blogs from '../../api/blogs'

const BlogSection = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (

        <section className="bg-[#F2EFE8] pt-[43px] pb-[70px]">
            <div className="wraper">
                <div className="grid grid-cols-12 gap-4">
                    {blogs.map((blog, bl) => (
                        <div className="col-span-4 md:col-span-6 col:col-span-12" key={bl}>
                            <div className="mb-[30px] group">
                                <div className="bg-white transition ease-in-out duration-300">
                                    <div className="w-full" style={{ height: "300px", overflow: "hidden" }}>
                                        <img className="w-full h-full object-contain transition ease-in-out duration-300 group-hover:grayscale" src={blog.screens} alt="" />
                                    </div>
                                </div>
                                <div className="bg-white pt-[20px] px-[20px] pb-[30px]">
                                    <h3 className="text-[24px] leading-[36px] font-heading-font font-medium mb-[20px]">
                                        <a onClick={ClickHandler} href={blog.articleLink} target="_blank" className="text-[#000] transition ease-in-out duration-300 hover:text-[#867662]" rel="noreferrer">{blog.title}</a>
                                    </h3>
                                    <ul className="flex items-center">
                                        <li className="text-[#867662] relative px-[10px]">{blog.create_at}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    );
}

export default BlogSection;