import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/flogo.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Footer = (props) => {
    return (
        <footer className="relative bg-[#182848] z-10">
            <div className="pt-[100px] pb-[90px] md:py-[90px] md:pb-[20px] overflow-hidden relative -z-10">
                <div className="wraper">
                    <div className="flex flex-wrap justify-between items-start">
                        <div className="w-[420px] xl:w-[355px] lg:w-[297px] pr-[125px] md:mb-[40px] lg:pr-0 relative text-left">
                            <div className="mb-7">
                                <Link className="text-[45px] font-bold flex items-center text-white" to="/">
                                    <img src={Logo} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="mb-7">
                            <h5 className="text-[18px] font-medium font-heading-font text-white capitalize">
                                <Link onClick={ClickHandler} className="text-white hover:text-[#867662] transition-all" to="/">Home</Link>
                            </h5>
                        </div>
                        <div className="mb-7">
                            <h5 className="text-[18px] font-medium font-heading-font text-white capitalize">
                                <Link onClick={ClickHandler} className="text-white hover:text-[#867662] transition-all" to="/about">About</Link>
                            </h5>
                        </div>
                        <div className="mb-7">
                            <h5 className="text-[18px] font-medium font-heading-font text-white capitalize">
                                <Link onClick={ClickHandler} className="text-white hover:text-[#867662] transition-all" to="/services">Services</Link>
                            </h5>
                        </div>
                        <div className="mb-7">
                            <h5 className="text-[18px] font-medium font-heading-font text-white capitalize">
                                <Link onClick={ClickHandler} className="text-white hover:text-[#867662] transition-all" to="/Articles">Articles</Link>
                            </h5>
                        </div>
                        <div className="mb-7">
                            <h5 className="text-[18px] font-medium font-heading-font text-white capitalize">
                                <Link onClick={ClickHandler} className="text-white hover:text-[#867662] transition-all" to="/#contact">Contact</Link>
                            </h5>
                            <div className="mt-[30px] pr-[150px] leading-[30px] text-[16px]">
                                <p className="text-[#ddd] mt-[20px]"><i class="ti-mobile mr-[8px]"></i> 719-495-8407</p>
                                <p className="text-[#ddd] mt-[20px]"><i class="ti-email mr-[8px]"></i> <a href="mailto:rob@rmhaddock.com">rob@rmhaddock.com</a></p>
                                <p className="text-[#ddd] mt-[20px] addressContact"><i class="ti-location-pin mr-[8px]"></i><span>12730 Black Forest Road<br />Colorado Springs, CO<br />80908</span></p>
                                <p className="text-[#ddd] mt-[20px]"><i class="ti-linkedin mr-[8px]"></i> <a href="https://www.linkedin.com/in/robhaddock/">LinkedIn</a></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="wraper">
                <div className=" border-t-1 border-[rgba(192,181,150,.3)] relative">
                    <div className="h-[1px] absolute left-[15px] top-0 bg-[#ffffff0d] w-[calc(100%+30px)]"></div>
                    <p className="text-center text-white text-[14px] py-[20px]">&copy; 2024 Metal Roof Advisory Group. All rights reserved. </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;