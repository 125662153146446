import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               One thing I have learned in life is to always have a Plan B. Things don’t always go the way you think they will. The pandemic is a stark reminder. For some, with the onset of COVID-19, it appeared as though the greatest economic boom period in history had come to a complete standstill. And today, with increasing costs of goods and raw materials, supply chain backlogs and the labor shortage, it can still feel like an uphill battle. So, it is imperative to have a Plan B. Not having a Plan B can cause unnecessary stress and anguish that affects both your personal and business relationships. Who wants that?
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               In the wake of the pandemic, the S-5! team put our heads together, mobilized, got creative and activated a plan to not only keep our business afloat but to thrive and be well-positioned for the future. Our Plan B was to kick off a series of educational webinars and video conferences to stay in front of customers and provide education and training opportunities. We introduced a more user-friendly website with revamped content, up-to-date information and additional resources, including a new resource center, a new training center and a host of new web-based tools to assist our customers. That plan was a true team effort and worked well for us.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Then, the next challenge was upon us. Like all sectors of the economy, our industry has seen raw material price increases. Steel companies had shut down production and inventories became decimated. That’s raw material at its source. Other mills beyond steel, like aluminum, stainless, lumber and consumer goods shut down as well (both domestic and foreign). The result was an increase in demand along with a decrease in supply.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               At S-5!, we experienced this first hand. Our key suppliers for raw materials suffered shortages. So, back to Plan B! How would we source alternative suppliers when we have spent the last 30+ years establishing long-standing relationships with our existing suppliers?
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Well, it begins with some honest conversation. And as it turned out, they understood our situation. Matter of fact, they were in the same boat–trying to meet the demand (we are one of their largest customers). They said to us, “…help us out and find a secondary supplier because we just can’t meet your demand.” So, after much research and vetting, we have onboarded those additional raw material suppliers but are still able to maintain strong relationships with existing key suppliers and customers. We just got creative, shifted thinking and developed new relationships without compromising the old.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Although for some, Plan B can get in the way of loyalty, I learned along the way, it is foolish not to have a Plan B. You can get trapped in tunnel vision, driving down the road. Just look at the map and figure out a different route. You have got to do what you have to do to get where you are going.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               For example, one of our European distributors is moving 60% of product by air freight now because the cost to ship via containers has more than tripled. He has now doubled his business by air freight. He’s doing what he has to do to get product to his customers. It goes back to shifting thinking, breaking conventions, being flexible and going with the flow.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Additionally, Plan B can present opportunities. Throughout the health crisis, we have looked for opportunities instead of problems. Those opportunities were (and still are) out there—to respond to the near term, manage differently and plan for the longer term. Just shift thinking, re-direct resources, re-invest and prepare for the (new and increased) demand. Make sure you have a Plan B.
            </p>
         </div>
      </div>

   )
}

export default blogPage;