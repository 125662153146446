import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">Overview</h3>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Part 1 of this series outlined the advantages of installing solar photovoltaic (PV) systems on metal roofs: the lifecycle costs of rooftop solar installations; the solar PV system and roof together considered as a single asset; and the resulting lowest lifetime costs for this combined asset.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Part 2 provided an overview of the various types of PV mounting systems for metal roofs: the advantages and disadvantages for each type of mounting system; and comparisons between rail-based and rail-less systems. (See Figure 1)
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Part 3 will dig into more detail and the critical technical factors for solar PV systems specific to mounting on metal roofs. While contractors direct much scrutiny to the modules and inverters, often too little attention is given to the mounting system that marries the expensive PV array to the host roof. Although making up only about 5% of total installed PV system costs, the most troublesome issues arise from the interface of array-to-roof. Knowledge, design and planning are critical to a long-lasting, low-cost, installed PV system.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Technical details will be discussed, including performance and manufacturing specification requirements and certifications. Dos and don’ts of material compatibility, PV system installation details, physical, mechanical and electrical aspects pertaining to mounting solar to the metal roof will also be addressed.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Three technical bulletins published by the Metal Construction Association (MCA) that support and supplement the content presented here and should be consulted for further information include Metal Roofing and PV Solar Systems; Parts 1&2, and Fastener Compatibility with Profiled Metal Roof and Wall Panels. These papers are available through the MCA website, <a href="www.metalconstruction.org/Tech-Resources">www.metalconstruction.org/Tech-Resources</a>.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">© 09-2021</p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">Discussion</h3>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">Site Conditions and Strength Requirements</h4>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               As with any construction project, PV systems and their components should be designed with careful consideration of site conditions. PV systems installed on existing roofs depend on the building’s structural system for support, so it is important to carefully consider environmental loadings in this context. Most notably, PV systems impact gravity and wind loading on the roof and underlying structure. But PV systems also impact snow and seismic loads where applicable and may affect fire performance as well.
            </p>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">Technical Requirements of PV Mounting Systems</h4>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Module mounting systems and components must be designed as required by local codes and:
            </p>
            <ul class="list-disc list-inside pl-[20px]">
               <li class="mb-[10px]">Engineered and designed in accordance with project-specific requirements</li>
               <li class="mb-[10px]">Proven by adequate and appropriate product testing conducted by independent third-party laboratories</li>
               <li class="mb-[10px]">Utilize certified materials exactly the same as those validated by testing</li>
               <li class="mb-[10px]">Produced in a controlled, audited facility with quality standards independently certified by a codeapproved third-party inspection program</li>
               <li class="mb-[10px]">Able to sustain their performance integrity for the entire anticipated PV system and/or roof service life</li>
            </ul>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               For a PV array installation over an existing metal roof, inspection may be necessary to verify the existing metal roof has a remaining roof life of at least 30 years. Such an assessment should be made by a qualified metal roofing professional as it is beyond the scope and the knowledge base of most solar Engineering, Procurement and Construction (EPC) companies.
            </p>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">Materials and Service Life Compatibility</h4>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Mounting system components, including attachment fasteners, should be fabricated from corrosion-resistant metals with a service life expectancy at least equivalent to the roof itself. This service life expectancy is generally in the 60-year range in most environments for unpainted 55% Al-Zn coated steel. The service life expectancy may be longer for aluminum, stainless, titanium-zinc and copper roofs, and maybe shorter for pre-painted metal roofs.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Preferred mounting system materials for copper roofs are brass or stainless-steel alloys. Anodized structural aluminum alloys may be used when brass alloys are not commercially available. Stainless steel (passivated) fasteners or caps should be used when portions of those fasteners are exposed to the elements.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Preferred mounting component materials for coated steel, aluminum, zinc, or stainless roofs are structural aluminum alloys and/or 300-series stainless steel. To avoid accelerated corrosion between components, compatibility of all metal attachments, interfaces and related fasteners should comply with the MCA Technical Bulletin Fastener Compatibility with Profiled Metal Roof and Wall Panels.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Standing seam metal roofs are generally recommended as PV solar mounting platforms. By design, these systems have few (if any) fastening penetrations and are generally attached to the structure or deck using concealed clips that allow freedom of expansion and contraction of the roof panels in response to temperature change. Attachment of mounting components through this roof type into the structure would create penetrations and limit thermal movement. Solar PV mounting systems for standing seam roof installations must be mechanically attached to the panel seams only via seam clamps in order to preserve the roof’s design features (and warranties).
            </p>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">Materials and Service Life Compatibility (continued)</h4>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Face-fastened metal roof types attach directly to the structure by design without the mentioned attributes of standing seam. The fastening of these roofs is typically completed with special long-life fasteners using coatings and/or metal alloys compatible with the roof material in terms of metallurgy and life expectancy. This type of system is protected from moisture intrusion by high-quality synthetic rubber seals protected from UV exposure by the fastener head design. While fastening penetration in these roof types is unavoidable, the waterproofing diligence of mounting system components should be of equal or better quality than the roof construction itself.
            </p>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">ASTM E 2140 Static Head Test</h4>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Solar PV mounting systems utilized on face-fastened metal roof systems are installed using bracket components mechanically attached either through the panels to the underlying substrate or building structure, or into the structural “ribs” of the roof sheets. The attachments should be validated by proper testing via ASTM E 2140, Standard Test Method for Water Penetration of Metal Roof Panel Systems by Static Water Pressure Head. Stainless steel (passivated) screws or caps should be used when portions of the fasteners are exposed to the elements.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Component and fastener designs should protect the sealing material from direct exposure to sunlight (e.g., as gaskets beneath and between metallic components). Some means of preventing over-compression of the gasket material should also be included in the component designs. Washers or sealants used for waterproofing should be of the same or greater chemistry as in the roof system to assure a comparable expected service life. Isobutylene/isoprene (butyl) polymers or materials with similar or greater service life are generally recognized as preferred sealants. Ethylene propylene diene monomer (EPDM) or materials with a similar or greater service life utilizing carbon black UV stabilizers are the recommended washer chemistry. Exposed, surface-applied sealants of any kind will not provide a comparable expected service life to the roof system and are not acceptable.
            </p>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">UL 2703 Certification</h4>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               All mounting system components shall be certified to UL 2703, UL Standard for Safety Mounting Systems, Mounting Devices, Clamping/Retention Devices, and Ground Lugs for Use with Flat-Plate Photovoltaic Modules and Panels. All components shall be fabricated from material certified to recognized standards (e.g., ASTM, ASME, SAE) with certificates of compliance (C of C) and proof of traceability.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Typical material requirements include, but are not limited to:
            </p>
            <ul class="list-disc list-inside pl-[20px]">
               <li class="mb-[10px]">Extruded structural grade aluminum components shall be fabricated using 6000-series alloy as defined by the Aluminum Association “Specifications for Aluminum Structures”</li>
               <li class="mb-[10px]">Cast aluminum components shall be compliant with ASTM B26/B26M, B108/B108M, or ASTM B85/B85M</li>
               <li class="mb-[10px]">Plate and sheet aluminum components shall be compliant with ASTM B209</li>
               <li class="mb-[10px]">Brass components shall be compliant with ASTM B927/B927M</li>
               <li class="mb-[10px]">Stainless-steel components shall be 300-series alloy compliant with ASTM A276/A276M</li>
            </ul>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Systems and components shall be proven by engineering calculations to withstand design loads mandated by the local building code. Proof of engineering by a duly registered design professional must be furnished to the client and the AHJ.
            </p>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">Tips to Assure Quality Compliance</h4>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Qualify the PV mounting system manufacturer carefully. How long a company has been in business may be somewhat irrelevant. The relevant questions include:
            </p>
            <ul class="list-disc list-inside pl-[20px]">
               <li class="mb-[10px]">How long has the supplier been providing engineered products for mounting solar PV systems onto metal roofs?</li>
               <li class="mb-[10px]">What warranties are offered and for what terms of coverage? (See Figure 4) Note: It is not prudent to rely solely on a warranty.)</li>
               <li class="mb-[10px]">Can the supplier’s track record be verified with customer testimonials and reference letters?</li>
               <li class="mb-[10px]">Has the required product testing and engineering been performed to address the specific design considerations of the project?</li>
               <li class="mb-[10px]">Does the roof manufacturer recommend these mounting system products?</li>
               <li class="mb-[10px]">Do the company’s business practices and certifications comport with this document and will the company back this compliance with written verification?</li>
               <li class="mb-[10px]">Are sales/marketing claims that substantiate the answers to these questions clear and verifiable?</li>
            </ul>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The integrity of the PV mounting system is vital to the performance of both the roof and solar PV system. The resistance to wind uplift and sliding snow forces should be considered in the structural design of the mounting system. The attachment frequency of the PV solar array must be engineered in accordance with these design loads and forces, and proven adequate by testing. (See Figure 5) At a minimum, the building and PV system owner(s) should obtain a letter from the vendor stating that testing, manufacturing, and product designs are in compliance with all provisions of this document along with obtaining the applicable lab load-test reports and job-specific structural engineering calculations that prove proper resistance of the PV mounting system to design loads.
            </p>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">Tips to Assure Quality Compliance (continued)</h4>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               For solar PV installations on metal roofs, many of the best practices are the same as installing solar PV on any roof type. Regardless of the roof type, each project must be engineered and designed to withstand the design forces and environmental conditions with certified components. In addition, having a well-trained team and a well-planned project down to every mechanical and electrical component is critical.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               However, there are also some unique aspects of installing solar on metal roofs. Metal roofs will outlast the service life of a solar PV system, so it is critical that the mounting system and all attachments protect and maintain the durability of the roof. That starts with the right mounting system manufacturer, one that has knowledge and expertise in metal roof construction.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Equally important is the use of materials and assemblies that are thoroughly tested and certified, especially components in direct contact with the roof. It is critical the attachments do not compromise the integrity of the metal roof.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               In addition to choosing the right manufacturer, an appropriate attachment should be chosen to achieve the best installation possible. Regardless of the mounting system, the metal roof attachment should be mechanically compatible with the mounting system. Substituting, adding, or stacking components not tested as an assembly is generally not acceptable. Second, the attachment system must be the best fit for the specific metal roof regardless of roof style (standing seam or exposed fastener roof).
            </p>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">Tips to Assure Quality Compliance (continued)</h4>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               For a standing seam roof such as the one on Figure 6, the recommended attachment clamps do not penetrate the roof; instead, they attach to the seam with a round-point fastener that will not compromise the paint or roof coating. Also, if the clamps fit the seam perfectly, the attachment system will cost less, perform better, and install more easily.
            </p>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               For an exposed fastened roof, the critical element is the integrity of the seal, since the attachment is designed to penetrate the roof.
            </p>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">Conclusions</h4>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               This paper illustrates that long-term performance of roof-mounted solar PV systems can only be achieved through careful planning, design, procurement, installation, and quality control from start to finish. It also shows how metal roofs enable these solar PV systems to live up to their stated potential durability, versatility, and inherent strength. Most critically, an owner of a PV system should ensure the combined system meets the following criteria:
            </p>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">Conclusions (continued)</h4>
            <ul class="list-disc list-inside pl-[20px]">
               <li class="mb-[10px]">Design to all required local codes and standards to ensure public safety</li>
               <li class="mb-[10px]">Carefully consider the service life of both the roof and the PV materials (including the mounting system), ensuring compatibility for all components</li>
               <li class="mb-[10px]">Utilize high-quality components which are carefully chosen, appropriately tested and certified by relevant third parties</li>
               <li class="mb-[10px]">Ensure all components installed on the project are consistent with testing and certification parameters</li>
               <li class="mb-[10px]">Properly written certifications, reports, etc., provided by all parties defining scope of work are provided to the owner(s) of the PV system and building</li>
            </ul>
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               As an aid to the recommendations of this paper, Appendix A presents a detailed checklist for the owner and/or the owner’s agents to assure these criteria are addressed. By applying the recommendations made in this paper and utilizing this checklist, the long-term combined value of the solar PV system and the building will be further maintained. This is particularly important because, as Parts 1 & 2 of this series illustrate, the solar PV systems and the roof combined should be regarded as a single asset.
            </p>
            <h4 class="text-[#000] text-[20px] leading-[28px] mb-[20px] font-bold">References</h4>
            <ol class="list-decimal list-inside pl-[20px]">
               <li class="mb-[10px]">Dutton, Ron; Haddock, Rob; Howard, Chuck; Kriner, Scott; Service Life Assessment of Low-Slope Unpainted 55%Al-Zn Alloy-Coated Steel Standing Seam Metal Roof Systems; Metal Construction Association; 2014, Updated 2018.</li>
               <li class="mb-[10px]">International Alloy Designations and Chemical Composition Limits for Wrought Aluminum and Wrought Aluminum Alloys, The Aluminum Association, August 2018.</li>
            </ol>
         </div>
      </div>

   )
}

export default blogPage;