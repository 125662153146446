import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Vendors have introduced numerous systems for mounting rooftop ancillaries such as snow retention, solar panels, service walkways, and various utilities to standing seam metal roofs. Each claims their system is the best, ultimate, first, or strongest. While such devices complement the roof—making our roofs much more user-friendly—the downside is this market space is completely unregulated. There are no industry standards or mandates for design, manufacture, use, or mechanical load-testing of rooftop attachments.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Many applications are not specifically engineered for design loads nor can they be because of insufficient testing or a lack of quality assurances in production to enable the necessary testing and engineering. This is important to note because these mounting systems transfer live loads into the roof sheathing and structure. A lack of codified standards can lead to failure causing death, injury, and property or roof damage, which are life-safety issues and potential liability for designers.
            </p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Liability of Installing Non-Engineered Systems
            </h3>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Today’s market is inundated with metal roof attachment products. Often by default, the designer becomes the ultimate decision maker and may assume liability when it comes to the selection of poorly designed or untested systems. If the designer believes the sales hype without properly vetting the system or its manufacture, the pitfall is this: Even if the product is installed according to the manufacturer’s instructions (but without proper testing/engineering documentation), it may fail, leaving the designer and contractor in a position of arguing over liability. Protection from this liability starts long before the product is on the roof.
            </p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Vet and Specify
            </h3>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Manufacturer transparency is at the heart of vetting a rooftop attachment system. A company that lauds the capabilities of its system but fails to provide proof of those claims may be blowing smoke. The designer should scrutinize manufacturer qualifications and certifications to ensure a safe, engineered application and long-term service on every project. This transparency should extend from raw material sourcing through manufacture and product handoff.
            </p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Proof of Engineering
            </h3>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Tensile Load Testing—To resist the in-service forces applied to any mounting system, we need to know at what point the attachment fails. Then, we can calculate the required population and spacing of the attachment(s) so it cannot fail. This requires an enormous amount of testing, and panel-specific results should be published on the vendor’s website for your inspection. If not, it may not exist.
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Long-Term Performance/Vendor Expertise—A product cannot perform better than its design. Has the vendor demonstrated sufficient experience? How long the company has been in business is irrelevant. The question is, “How long has its system been in use?” Can the vendor substantiate its track record and prove service/durability with interactive load testing tables and real-time engineering calculations? Ask for proof of it!
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Warranties—Verify that the manufacturer offers a meaningful performance (not just material) warranty. Obtain a copy before specification—and read the fine print!
            </p>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Engineering Calculations—These must be provided by the vendor on a project-specific basis and should incorporate the tested ultimate strength of the system with an appropriate factor of safety applied. Insist the vendor provide calculations before product selection. At a minimum, designers should require these calculations with submittals. Even better, require by specification that calculations are stamped by a registered professional engineer.
            </p>
            <h3 className="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Further Vetting Should Include:
            </h3>
            <ul className="list-disc list-inside pl-[20px]">
               <li className="mb-[10px]">
                  Use of chemically and mechanically certified material—Ask for certifications.
               </li>
               <li className="mb-[10px]">
                  Testing by a third-party ISO 17025 accredited lab—Ask for lab reports specific to the roof profile and manufacturer.
               </li>
               <li className="mb-[10px]">
                  Following ASTM material standards—Require a letter of compliance.
               </li>
               <li className="mb-[10px]">
                  Utilizing certified manufacturing processes and third-party audits in an ISO 9001-15 compliant facility—Ask to see the current ISO Certificate.
               </li>
               <li className="mb-[10px]">
                  Enforce the specification. Require the vendor to provide a written statement of compliance with Metal Construction Association online document, “Qualifying Snow Retention Systems for Metal Roofing,” which provides industry consensus criteria.
               </li>
            </ul>
            <p className="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               When designers do not thoroughly vet vendors and submittals, the mounting systems they select may fail, even when installed to the manufacturer’s instructions. System failure can threaten anything on the ground below and can also damage the roof. Protection from this liability starts long before the project is bid.
            </p>
         </div>
      </div>

   )
}

export default blogPage;