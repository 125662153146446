import React from 'react'


const About = (props) => {

    return (
        <section className="pt-[80px] pb-[60px]">
            <div className="wraper">
                <div className="grid grid-cols-12 items-center gap-3">
                    <div className="col-span-12">
                        <div className="mb-[20px]">
                            <h2 className="text-[#202020] text-[24px] text-center font-bold relative pb-[8px] before:absolute before:content-[''] before:bottom-0 before:transform before:-translate-x-[10px] before:w-[100px] before:h-[3px] before:bg-[#999999] before:rounded-full before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">Articles</h2>

                            <div className="pl-[10px] mt-[23px]">
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Rob is a well-known expert in the field of metal roofing and metal construction. Industry trade publications and associations rely on his expertise to help educate, inform and shape the industry. </p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal"> His writings and lectures number well in the 100s, both domestically and internationally, and have been translated into 10 languages. He is regularly asked to contribute articles to a variety of publications as well as white papers for the Metal Construction Association. Below is a collection of some of his writings.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;