import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ias from '../../api/awards'


const settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


const Attorney = (props) => {

    return (
        <section className="attorney-sec relative pt-[12px] pb-[18px] bg-[#F2EFE8] border border-[#ffffff] border-t-[53px]">
            <div className="wraper">
                <div className="col-span-12 text-center">
                    <div className="mb-[35px]">
                        <h2 className=" text-[24px] font-semibold text-[#202020] pb-[20px] relative before:absolute before:content-[''] before:bottom-0 before:left-[50%] before:bottom-0 before:transform before:-translate-x-1/2 before:w-[77px] before:h-[3px] before:bg-[#999999] before:rounded-full before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">Personal Awards</h2>
                    </div>
                </div>
                <div className="team-slider relative">
                    <Slider {...settings}>
                        {ias.map((ia, aitem) => (
                            <div className="overflow-hidden relative group" key={aitem}>
                                <div className='shadow-[1px_1px_5px_rgba(0,0,5,8%)] bg-[#ffffff] border border-[#867662] border-[1px] mx-[33px]'>
                                    <div className="expert-img px-[52px] py-[35px]">
                                        <div className="w-full" style={{ height: "300px", overflow: "hidden" }}>
                                            <img className="w-full h-full object-contain" src={ia.AtImg} alt="" />
                                        </div>
                                    </div>
                                    <div className="relative text-center mt-[10px] border-[#867662] border-t-[1px]">
                                        <h3 className="text-[24px] col:text-[20px] font-semibold py-[20px]  font-heading-font text-[#282e3f]">{ia.name}</h3>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

            </div>
        </section>
    )
}

export default Attorney;
