import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal roofing offers a unique platform for mounting a myriad of rooftop services and accessories, such as snow retention, service walkways and solar panels as the seams or ribs of the roof act as inherent, structural (and cost-free) attachment points. Further, metal outlasts all other roof types, and any attachment components should last the life of the roof!
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               While such simple attachments make metal roofs more user friendly, the downside is this market space is completely unregulated. There are no industry standards or mandates for the design, manufacture, use or testing of rooftop attachments. Lack of policing by building authorities makes it a buyer-beware scenario and incumbent on the contractor, owner or designer to properly vet the attachment devices they choose for their projects.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Inadequate attachments can lead to failure causing death, injury, and property or roof damage, which are real life-safety and liability issues.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Many applications are not specifically designed and engineered for the loads they will see in service, nor can they be due to insufficient testing and a lack of quality assurances in production. These are serious concerns because a failure can have grave consequences. Builders, contractors and designers need to know what steps they can take to protect themselves from potential liability and how best to vet these products with respect to sales claims.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Liability of Installing Non-Engineered Systems
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Often by default, the contractor becomes the ultimate decision maker and may be assuming liability when it comes to the selection of poorly designed or untested attachment products. If the contractor believes the sales hype without properly vetting the system, its testing or manufacture, the pitfall may be this. Even if the product is installed according to the manufacturer’s instructions, it may fail, leaving all parties arguing over the resulting liability. Protection from this liability starts long before the product is on the roof.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Vet and Specify
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Manufacturer transparency is at the heart of vetting a rooftop attachment system. The contractor should scrutinize manufacturer qualifications and certifications to ensure a safe, engineered application and long-term service on any project. This transparency should extend from raw material certification through manufacture and product handoff.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               <strong>Proof of Claims</strong>
               <br />
               <strong>Tensile Load Testing:</strong> To resist the in-service forces applied to any mounting system, it is crucial to know at what point the attachment fails. Then, the required population and spacing of the attachments can be engineered to prevent failure. This requires an enormous amount of testing. Panel-specific results should be published and if they are not, they likely do not exist.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               <strong>Long-Term Performance/Vendor Expertise:</strong> A product cannot perform better than its design. Has the vendor demonstrated sufficient experience? How long the company has been in business is irrelevant. The question is, How long and extensively has the system been in use? Can the vendor substantiate its track record and prove service/durability with interactive load testing information and real-time engineering calculations? Is there proof of performance?
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               <strong>Warranties:</strong> Verify that the manufacturer offers a meaningful performance (not just material) warranty. Obtain a copy before specification—and read the fine print!
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               <strong>Engineering Calculations:</strong> These must be provided by the vendor on a project-specific basis and should incorporate the tested allowable strength of the system with an appropriate factor of safety applied. Insist that design calculations are provided before product selection. At a minimum, contractors should require these calculations with submittals. Even better, require by specification that calculations are stamped by a registered professional engineer.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Further vetting and proofs should include:
            </p>

            <ul class="list-disc list-inside pl-[20px]">
               <li class="mb-[10px]">Use of chemically and mechanically certified material.</li>
               <li class="mb-[10px]">Proof of testing to ASTM E 2140 for any penetrative fastenings.</li>
               <li class="mb-[10px]">Proof of corrosion testing to ASTM B 117.</li>
               <li class="mb-[10px]">Mechanical load testing by a third-party ISO 17025 accredited lab.</li>
               <li class="mb-[10px]">Following ASTM material standards.</li>
               <li class="mb-[10px]">Utilizing certified manufacturing processes and third-party audits in an ISO 9001-15 compliant facility.</li>
               <li class="mb-[10px]">For solar and snow retention applications, request a letter stating compliance to all pertinent Metal Construction Association White Paper documents available on the association’s website.</li>
            </ul>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Without proper vetting, system failure can threaten anything on the ground below and damage the roof. Protection from this liability starts long before the project is bid.
            </p>
         </div>
      </div>

   )
}

export default blogPage;