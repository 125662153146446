import React from 'react'


const blogPage = (props) => {

   return (
      <div className="article grid-cols-12 grid gap-3">
         <div className="col-span-12 md:col-span-12">
            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               With recent news of the Inflation Reduction Act passing, the U.S. solar market is now poised to reach 30% of U.S. electricity generation by 2030. The new legislation includes a 10-year extension of the solar Investment Tax Credits (ITC), significant incentives to boost domestic manufacturing throughout the solar production supply chain, tax credits for energy storage, workforce development provisions and additional policies that promote a clean energy economy.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Solar projects eligible for the 30% ITC can increase their tax credit by an additional 10% (that’s up to 40% in total) by purchasing domestically produced products. This should offset potential price increases, reduce shipping and import costs, and likely increase the level of support for solar photovoltaics (PV) and other renewables in the United States—triggering an avalanche of solar development throughout the country.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               How do metal roofs play into the equation?
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Metal roofing provides an ideal platform for mounting rooftop solar as it is the only roof type with a service life (in the range of 50-70 years) that actually exceeds the service life of a solar PV system (an average of 32.5 years and growing year-over-year). It is also the most sustainable roofing type and is conducive to lower solar installation costs. Most alternative roofing types will expire long before the life of the PV system, leading to costly disassembly of the PV array, re-roofing and re-assembly.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               These re-roofing and re-assembly expenses erode the otherwise attractive financial metrics of roof-mounted PV. High-end tile may be the only exception to the need for re-roofing, but its price tag is the same as standing seam metal roofing. But mounting solar to tile is invasive and very tricky to do without breaking tiles, so the cost is also much higher than mounting to metal. Simply put, when all the facts are known, the standing seam metal roof is the only roof type worthy of consideration for solar PV with face-attached metal profiles at a close second place.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               How do national and/or local energy policies and building/electrical codes play into the use of solar?
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The role of codes and regulations is a double-edged sword. Some are very positive for solar, such as the residential solar mandates for new buildings enacted in California two years ago, while others may increase hurdles, making it more complex and difficult to install solar. As the use of solar increases, so do the number and revisions of codes and standards. This is inevitable and the right thing to do but may inadvertently increase the hurdles to deploying solar. Some energy conservation policies are focused on energy efficiency first, which may reduce the demand for solar.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               That said, various municipalities and even states have enacted regulations and building codes mandating the installation of solar PV or solar-ready design on new building construction. This is a major shift from the past when there was no consideration for accommodating solar with new construction design, and then retroactively fitting solar to the roof as well as possible. New mandates will result in the accelerated growth of rooftop solar, with the intent also to reduce costs and maximize the energy output of solar installations – leading to higher ROIs with fewer hurdles in deploying solar PV.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The key to complying with these mandates is in the upfront planning and design of new buildings with respect to factors not traditionally considered – factors focused on the anticipation of a solar installation on a new building.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               For example, according to St. Louis’s solar-ready regulation passed in December 2019, the area of a new commercial building’s roof, which is functional for solar must be at least 40% of the total roof area, often referred to as the “solar-ready zone.” For new residential homes, the solar-ready zone must be at least 600 square feet and oriented between 110 and 270 degrees from true north to the southernmost point as possible — to produce more energy.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               As more buildings are constructed with solar installed or solar-ready, the demand for better solutions will foster greater innovation of products and technology to allow a building, its roof and solar PV to work as a single system. This could be new products performing multiple functions, such as building-integrated PV (BIPV), which has been in the industry for years but is relatively unsuccessful due to economics and technical difficulty.
            </p>

            <h3 class="text-[#000] text-[22px] leading-[28px] mb-[20px] font-bold">
               Return-on-Investment
            </h3>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               Often, when the decision is made to install solar, a new roof is required. Metal roofing has become a driver for roof type selection in these cases because not only can a metal/solar roof system have lower initial cost than other roof system combinations, but it also leads to improvements in the lifetime return on investment (ROI) of the system. Therefore, the PV array and the roof should be regarded as a single asset.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               When computing ROI within the solar pro forma, inverter replacement is usually factored in at about year 15 – but what about the cost of roof replacement? The solar array must be totally dismantled and then re-installed on the replacement roof. Factor in the inevitable cost of roof replacement and the advantages of a metal roof become clearly obvious. The metal roof avoids replacement due to its long service life. The roof will still be going strong long after the service life of the solar array has expired.
            </p>

            <p class="text-[#000] text-[16px] leading-[28px] mb-[20px] font-normal">
               The Metal Construction Association recently published a three-part white paper series on solar PV and metal roofing. Part 1 focuses on solar/roof service life comparisons. Part 2 explains the common types of mounting systems for metal roofs, associated risks and the pros/cons of each. Part 3 digs into more detail and the critical technical factors for solar PV systems specific to mounting on metal roofs.
            </p>
         </div>
      </div>

   )
}

export default blogPage;