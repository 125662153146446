import React from 'react'
import { Link } from 'react-router-dom'
import about from '../../images/about/rob-aboutme.jpg'


const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="pt-[120px] pb-[100px] border border-[#F2EFE8] border-t-[33px]">
            <div className="wraper">
                <div className="grid grid-cols-12 items-center gap-3">
                    <div className="col-span-6 md:col-span-12">
                        <div className="relative mb-[20px]">
                            <div className=" relative max-w-[470px] before:absolute before:bg-[#BEB499] before:w-[107.38%] before:h-[110.5%] before:content-[''] before:-z-10 before:left-[-13%] before:top-[-3.5%] before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
                                <img className="w-full" src={about} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 md:col-span-12">
                        <div className="mb-[20px] md:mt-[65px]">
                            <h2 className="text-[#202020] text-[24px] font-bold relative pb-[8px] before:absolute before:content-[''] before:bottom-0 before:transform before:-translate-x-[10px] before:w-[230px] before:h-[3px] before:bg-[#999999] before:rounded-full before:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]">About Rob</h2>

                            <div className="pl-[10px] mt-[23px]">
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">Rob Haddock is a metal roof consultant, author, speaker, inventor and the founder of Colorado Springs-based S-5! He was the inventor of the non-invasive clamps for fastening accessories to standing seam metal roofs and originator of a new resulting product category: engineered, manufactured metal roof attachment solutions. His personal BHAG is to promote metal roofing as the most desired, accepted, user-friendly, most coveted roof in the construction industry globally.</p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">With 50+ years of industry experience, Rob has an unwavering belief that metal roofing is superior to other types of roofing and strongly believes that S-5!’s innovations are making metal the most user-friendly roof type on the market.</p>
                                <p className="text-[#000] text-[16px] leading-[28px] mb-[30px] font-normal">He began his work life as a cowboy and rodeo competitor, tending a 5,000-acre ranch in the Colorado mountains―yes, the boots and hat are real. He also worked construction, and at the age of 19, he founded a company that erected pre-engineered steel buildings. That was Rob’s introduction to the metal construction industry.</p>
                                <div className="mb-[50px] col:mb-[20px]">
                                    <Link onClick={ClickHandler} to="/about" className="bg-[#867662] relative cursor-pointer text-[22px]
                                font-semibold text-white px-[8px] py-[8px] mt-[40px]  capitalize inline-block transition ease-in-out duration-300 hover:bg-[#d4c291] border border-[#BEB499] border-[2px]">More About Us <i className='ti-angle-right'></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;