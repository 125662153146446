import simg from '../images/practice/fc-icon.svg'
import simg2 from '../images/practice/is-icon.svg'
import simg3 from '../images/practice/wp-icon.svg'
import simg4 from '../images/practice/tl-icon.svg'
import simg5 from '../images/practice/ic-icon.svg'

const Practices = [
    {
        Id: '1',
        sImg: simg,
        sTitle: 'Forensic Consulting With Respect to Roof Failure Analysis',
    },
    {
        Id: '2',
        sImg: simg2,
        sTitle: 'Industry Standard Practices ',
    },
    {
        Id: '3',
        sImg: simg3,
        sTitle: 'Generation of White Papers Best Practices',
    },
    {
        Id: '4',
        sImg: simg4,
        sTitle: 'Teaching and Lecturing on the subject of Metal Roofing ',
    },
    {
        Id: '5',
        sImg: simg5,
        sTitle: 'Expert Testimony ',
    },
]
export default Practices;