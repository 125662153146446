import atimg3 from '../images/expert/astm_logo.svg'
import atimg4 from '../images/expert/csi_logo.svg'
import atimg7 from '../images/expert/mca_logo.svg'
import atimg10 from '../images/expert/iibec_logo.svg'
import atimg11 from '../images/expert/MTC.png'


const Attorneys = [
   {
      Id: '1',
      AtImg: atimg3,
      name: 'ASTM  Appreciation Award',
      year: '2023',
   },
   {
      Id: '2',
      AtImg: atimg4,
      name: 'CSI Ben John Small Technical writing award',
      year: '2018',
   },
   {
      Id: '3',
      AtImg: atimg7,
      name: 'MCA Larry Swaney Award',
      year: '2015',
   },
   {
      Id: '4',
      AtImg: atimg3, //need update
      name: 'ASTM Carl G. Cash Award',
      year: '2015',
   },
   {
      Id: '5',
      AtImg: atimg3,
      name: 'Eighth Symposium Presentation Excellence Award ',
      year: '2015',
   },
   {
      Id: '6',
      AtImg: atimg11,
      name: 'Inaugural Inductee to Metal Construction Hall of Fame',
      year: '2012',
   },
   {
      Id: '7',
      AtImg: atimg10,
      name: 'RCI (IIBEC) Richard "Horowitz” Award',
      year: '1999',
   }
]

export default Attorneys;